export const sizing = {
  SMALLEST: 10,
  SMALLER: 12,
  SMALL: 14,
  MEDIUM: 16,
  LARGE: 18,
  LARGER: 24,
  LARGEST: 32
};

export const styling = {
  NORMAL: 'Helvetica Neue, sans-serif',
  REGULAR: 'Roboto',
  CONDENSED: 'Roboto Condensed'
};