import styled from 'styled-components'
import colors from '../../../Theme/colors'
import { styling, sizing } from '../../../Theme/fonts'

export const CardWrapper = styled.div`
  width: 100%;
  height: 150px;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  font-family: ${styling.NORMAL};
  padding: 8px;
  transition: transform 1s ease;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  :hover {
    transform: scale(1.02);
  }
`

export const CardHeader = styled.div`
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: center;
`
export const Img = styled.img`
  width: 100%;
  transition: 2s ease;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`
export const CardBody = styled.div`
  width: 45%;
  height: 100%;

  /* p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  } */
`
export const Title = styled.h2`
  font-size: 22px;
  font-weight: bold;
  margin: 0;
`
export const Desc = styled.p`
  color: ${colors.COLOR_DEFAULT_BUTTON};
  font-size: ${sizing.MEDIUM}px;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const CardFooter = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`
export const Info = styled.div`
  display: flex;
  align-items: center;
  font-size: ${sizing.MEDIUM}px;

  p {
    margin: 8px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    :hover {
      color: ${colors.GREEN};
    }
    a {
      color: ${colors.BLACK};
      text-decoration: none;
      white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
      :hover {
        color: ${colors.GREEN};
        
      }
    }
  }
`
export const InfoIcon = styled.div`
  color: ${colors.COLOR_DEFAULT_BUTTON};
`
export const CardShare = styled.div`
  width: 5%;
  margin: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  div:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  div:nth-child(2) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 8px auto;

    a {
      width: 20px;
      padding: 3px;
      color: ${colors.COLOR_DEFAULT_BUTTON};
      cursor: pointer;
    }
  }

  a {
    color: ${colors.BLACK};
    :hover {
      color: ${colors.GREEN};
    }
  }
`
