import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts'
import imagem from '../../Assets/Images/contato.jpg'
import InputTelefone from '../../Components/InputTelefone/index'

export const ContatoWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 107px);
  background-image: url(${imagem});
  background-size: cover;
  margin: 0 auto;
  font-family: ${styling.NORMAL};

  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const FormWrapper = styled.div`
  width: 47.5%;
  margin: 35px 25px;
  height: 100%;
  background-color: ${colors.WHITE};
  border-radius: 10px;
  padding: 10px;
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-width: 688px) {
    width: 100%;
  }
`
export const Title = styled.h2`
  margin: 10px;
  font-size: 22px;
  text-transform: uppercase;
  color: ${colors.COLOR_DEFAULT_BUTTON};
`
export const Contatos = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: calc(100% - 20px);
  padding: 5px 10px 10px;
  font-size: ${sizing.SMALL}px;

  div:nth-child(n) {
    width: 50%;
  }

  @media (max-width: 688px) {
    flex-direction: column;

    div:nth-child() {
      width: 100%;
    }
  }
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - 20px);
  padding: 0 10px;
  gap: 8px;
`
export const Input = styled.input`
  border-radius: 15px;
  padding: 10px 8px;
  color: ${colors.BLACK};
  font-size: ${sizing.SMALL}px;
  border: none;
  background-color: ${colors.VIOLET_BACKGROUND};
  :focus {
    outline: none;
    border: 0.5px orange;
  }
`
export const Telefone = styled(InputTelefone)`
  border-radius: 15px;
  padding: 6px 8px;
  color: ${colors.BLACK};
  border: none;
  background-color: ${colors.VIOLET_BACKGROUND};
  :focus {
    outline: none;
    border: 0.5px orange;
  }
`

export const TextArea = styled.textarea`
  border-radius: 15px;
  resize: none;
  padding: 10px 8px;
  color: ${colors.BLACK};
  font-size: ${sizing.SMALL}px;
  background-color: ${colors.VIOLET_BACKGROUND};
  border: none;
  :focus {
    outline: none;
    border: 0.5px orange;
  }
`

export const Button = styled.button`
  width: 200px;
  margin-left: auto;
  border: none;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  padding: 8px;
  border-radius: 15px;
  color: ${colors.WHITE};
  cursor: pointer;
  font-weight: bold;
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALL}px;
`
