import { React, Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GetEstados, GetCidades, GetCategorias } from '../../Service/ApiService'

import { Container, FormWrapper, FormHeader, TitleMain, Title, FormBody, Combo, Input, Button } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Loader } from '../Loader'

function FormSearch({ onSearch, value }) {
  const tokenApp = useSelector((state) => state.TokenApp)
  const [cidades, setCidades] = useState([])
  const [estados, setEstados] = useState([])
  const [categorias, setCategorias] = useState([])
  const [values, setValues] = useState({ produto: '', nmCategoria: '', estado: '', cidade: '', filtro: '' })
  const [valSelect, setValSelect] = useState({})

  function onChange(ev) {
    const { name, value } = ev.target
    if (name === 'produto') {
      setValues({
        ...values,
        [name]: value,
        nmCategoria: ev.nativeEvent.target[ev.nativeEvent.target.selectedIndex].text,
      })
    } else if (name === 'filtro') {
      if (value.trim() !== '')
      {
        setValues({ ...values, [name]: value })
      }
      else{
        setValues({ ...values, [name]: '' })
      }
    } else {
      setValues({ ...values, [name]: value })
    }
  }
  
  function onChangeCat(ev, name) {
    //console.log(ev)
    setValSelect({ ...valSelect, [name]: ev })
    if (name === 'produto') {
      setValues({
        ...values,
        [name]: ev.value,
        nmCategoria: ev.label,
      })
    } else setValues({ ...values, [name]: ev.value })
  }

  useEffect(() => {
    if (value !== undefined) {
      setValues(value)
    }

    setValSelect({
      produto: value?.produto ? { value: value.produto, label: value.nmCategoria } : null,
      estado: value?.estado ? { value: value.estado, label: value.estado } : null,
      cidade: value?.cidade ? { value: value.cidade, label: value.cidade } : null,
    })
  }, [value])

  useEffect(() => {
    async function loadData() {
      var _estados = await GetEstados(tokenApp)
      let _estItens = []
      _estados.forEach((d) => {
        _estItens.push({ value: d, label: d })
      })
      setEstados(_estItens)

      var _categorias = await GetCategorias(tokenApp)
      let _catItens = []
      _categorias.forEach((d) => {
        _catItens.push({ value: d.categoriaId, label: d.nome })
      })
      setCategorias(_catItens)
    }
    loadData()
  }, [tokenApp])

  useEffect(() => {
    async function loadCidades() {
      setValSelect({ ...valSelect, cidade: null })
      setValues({ ...values, cidade: '' })
      if (!values.estado) {
        setCidades()
      } else {
        var _cidades = await GetCidades(tokenApp, values.estado)
        let _cidItens = []
        _cidades.forEach((d) => {
          _cidItens.push({ value: d, label: d })
        })
        setCidades(_cidItens)
      }
    }
    loadCidades()
  }, [values.estado])

  function onBuscar() {
    onSearch(values)
  }

  return (
    <Fragment>
      <Container>
        <TitleMain>Seu facilitador de obras!</TitleMain>
        <FormWrapper>
          <FormHeader>
            <Title>Busca</Title>
            <a href="/ComoTrabalhamos">Como funciona?</a>
          </FormHeader>
          <FormBody>
            <Combo
              placeholder="Produto / Serviço"
              value={valSelect.produto}
              onChange={(e) => {
                onChangeCat(e, 'produto')
              }}
              name="produto"
              id="produto"
              closeMenuOnSelect={true}
              className="basic-multi-select"
              classNamePrefix="select"
              options={categorias}
            />

            <Combo
              placeholder="Estado"
              value={valSelect.estado}
              onChange={(e) => {
                onChangeCat(e, 'estado')
              }}
              name="estado"
              id="estado"
              closeMenuOnSelect={true}
              className="basic-multi-select"
              classNamePrefix="select"
              options={estados}
            />

            <Combo
              placeholder="Cidade"
              value={valSelect.cidade}
              onChange={(e) => {
                onChangeCat(e, 'cidade')
              }}
              name="cidade"
              id="cidade"
              closeMenuOnSelect={true}
              className="basic-multi-select"
              classNamePrefix="select"
              options={cidades}
            />
            <Input
              type="search"
              placeholder="Palavra-Chave"
              id="filtro"
              name="filtro"
              onChange={onChange}
              value={values.filtro}
            />

            <Button type="button" onClick={onBuscar}>
              Buscar
              <FontAwesomeIcon width={64} icon={faSearch} />
            </Button>
          </FormBody>
        </FormWrapper>
      </Container>
    </Fragment>
  )
}

export default FormSearch
