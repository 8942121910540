
import styled from 'styled-components';
import colors from '../../../Theme/colors';
import { styling, sizing } from '../../../Theme/fonts';

export const CardWrapper = styled.div`    
  width: 23%;
  min-width: 150px;
  height: 45vh;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${colors.WHITE};
  border-radius: 5px;
  margin-top: 15px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  transition: transform 1s ease;

  &:hover{
    transform: scale(1.075);
  }

  @media(min-width: 1024px){
    width: 22%;
    height: 300px;
  }
    @media(max-width: 1023px){
      width: 40%;
      height: 300px;
    }
    @media(max-width: 1024px) and (orientation: landscape){
      width: 22%;
    }
    @media(max-width: 640px){
      width: 100%;
      height: 325px;
    } 
    
    @media(max-width: 640px) and (orientation: landscape){
      width: 45%;
    }
`;

export const CardHeader = styled.div`
  width: 100%;
`;
export const NomeLoja = styled.p`
  position: absolute; 
  bottom: 5px;
  right: 5px;
  font-weight: 400;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  padding: 3px;
  width: 60%;
  color: ${colors.WHITE};
  text-align: center;
`;

export const Img = styled.img`
  width: 100%; 
  aspect-ratio: 3 / 2;
  object-fit: cover;
  background-color: grey;
  transition: transform 1s;
`;

export const CardBody = styled.div`
  padding: 4px;
  width: 85%;
  margin: 0 auto;
`;

export const Title = styled.div`
  color: black;
  width: 100%;
  margin: 0 auto;
  
  white-space: wrap;
  overflow: hidden; 
  text-overflow: ellipsis;
`;

export const CardFooter = styled.div`
  width: 100%;
  padding: 0 10% 10px;
`;

export const Button = styled.button`
    width: 100%;
    font-family: ${styling.NORMAL};
    font-size: ${sizing.SMALLER}px;
    font-weight: bold;
    padding: 8px;
    color: ${colors.WHITE};
    background-color: ${colors.COLOR_DEFAULT_BUTTON};
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 5px;

    :hover{      
      font-weight: 600;
      background-color: ${colors.GREEN};
    }
`;