import React, { useState, useEffect, Fragment } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faShareSquare } from '@fortawesome/free-regular-svg-icons'
import { faGlobe, faMapMarkerAlt, faTimes, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faPinterest, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import { GetAnuncios, AddFavorito } from '../../Service/ApiService'
import { toast, Bounce } from 'react-toastify'
import { useSelector } from 'react-redux'

import colors from '../../Theme/colors'

import {
  Info,
  CardContato,
  Logo,
  Share,
  Title,
  Row,
  ContatoItem,
  Descricao,
  Categorias,
  CardCategoria,
  Imagens,
  FrameFacebook,
  Wrapper,
  Container,
  CardHeader,
  Close,
} from './style'

import { Modal, ModalBody } from 'reactstrap'

import GalleryImages from '../GalleryImages'
import MapaModal from '../Mapa'

const ModalLoja = ({ showModal, setShowModal, infos }) => {
  const { nome, descricao, urlFacebook, urlLogo, urlSite, numWhatsApp, telefone } = infos
  const tokenType = useSelector((state) => state.TokenType)
  const tokenApp = useSelector((state) => state.TokenApp)
  const [favorite, setFavorite] = useState(false)
  const [anuncios, setAnuncios] = useState()
  const [share, setShare] = useState(false)

  useEffect(() => {
    async function loadImagens() {
      var result = await GetAnuncios(tokenApp, infos.participanteId)
      if (result.length === 0) {
        setAnuncios(undefined)
      } else {
        setAnuncios(result)
      }
    }
    if (infos.participanteId) loadImagens()
  }, [infos])

  async function onFavorito(ev) {
    if (tokenType !== 'Cli') {
      window.location.href = '/Login?f=' + infos.participanteId
    } else {
      favorito()
    }
  }

  async function favorito() {
    var response = await AddFavorito(tokenApp, infos.participanteId)
    if (response.result) {
      setFavorite(!favorite)
    } else {
      toast(response.message, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
    }
  }

  return (
    <>
      {showModal ? (
        <Modal size="xl" isOpen={showModal} toggle={() => setShowModal(!showModal)}>
          <ModalBody>
            <Wrapper>
              <Container>
                <Row>
                  <Info>
                    <CardHeader>
                      <Logo>
                        <img src={urlLogo} alt={nome} />
                        <Share>
                          <div>
                            <FontAwesomeIcon icon={faStar} color={favorite ? colors.YELLOW : ''} onClick={onFavorito} />
                            <FontAwesomeIcon icon={faShareSquare} onClick={() => setShare(!share)} />
                          </div>
                          {
                            {
                              true: (
                                <div className="btn">
                                  <a
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon width={64} icon={faFacebook} />
                                  </a>
                                  <a
                                    href={`https://twitter.com/intent/tweet?text=${nome}&url=${window.location.href}&related=`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon width={64} icon={faTwitter} />
                                  </a>
                                  <a
                                    href={`http://pinterest.com/pin/create/button/?url=${window.location.href}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <FontAwesomeIcon width={64} icon={faPinterest} />
                                  </a>
                                </div>
                              ),
                            }[share]
                          }
                        </Share>
                      </Logo>

                      <CardContato>
                        <Title>{nome}</Title>

                        <ContatoItem>
                          {infos.endereco !== ' - ' && (
                            <Fragment>
                              <FontAwesomeIcon width={64} icon={faMapMarkerAlt} />
                              <p>{infos.enderecoComp}</p>
                            </Fragment>
                          )}
                        </ContatoItem>
                        <ContatoItem>
                          {urlSite !== '' && (
                            <Fragment>
                              <FontAwesomeIcon width={64} icon={faGlobe} />
                              <p>
                                <a href={`${urlSite}`} target="_blank" rel="noreferrer">
                                  {urlSite}
                                </a>
                              </p>
                            </Fragment>
                          )}
                        </ContatoItem>
                        <ContatoItem>
                          {numWhatsApp !== '' && (
                            <Fragment>
                              <FontAwesomeIcon icon={faWhatsapp} />
                              <p>
                                <a href={`https://wa.me/${numWhatsApp}`} target="_blank" rel="noreferrer">
                                  {numWhatsApp}
                                </a>
                              </p>
                            </Fragment>
                          )}
                        </ContatoItem>
                        <ContatoItem>
                          {telefone !== '' && (
                            <Fragment>
                              <FontAwesomeIcon icon={faPhoneAlt} />
                              <p>{telefone}</p>
                            </Fragment>
                          )}
                        </ContatoItem>
                      </CardContato>
                    </CardHeader>

                    <Descricao>{descricao}</Descricao>
                  </Info>
                  {infos.endereco !== ' - ' && <MapaModal address={infos.enderecoComp} />}
                </Row>

                <Row>
                  {infos.categorias !== undefined && (
                    <Categorias>
                      {infos.categorias.map((item, i) => (
                        <CardCategoria key={i}>
                          <input id={`InCat_${i}`} value={item.nome} readOnly />
                          <label id={`LbCat_${i}`}>{item.nome}</label>
                        </CardCategoria>
                      ))}
                    </Categorias>
                  )}
                </Row>

                <Row>
                  {urlFacebook && (
                    <FrameFacebook
                      src={`https://www.facebook.com/plugins/page.php?href=${urlFacebook}&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                      className="iframeFacebook"
                      frameBorder="0"
                      allowfullscreen="true"
                      scrolling="false"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></FrameFacebook>
                  )}

                  {anuncios && (
                    <Imagens>
                      <GalleryImages anuncios={anuncios} logoAnuncio={urlLogo}></GalleryImages>
                    </Imagens>
                  )}
                </Row>
              </Container>

              <Close>
                <FontAwesomeIcon icon={faTimes} onClick={() => setShowModal(!showModal)} />
              </Close>
            </Wrapper>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  )
}

export default ModalLoja
