import { Fragment, React } from 'react'

import { Wrapper, Container, Title, Content, Share, Status, Image } from './styles'
import BannerCarrossel from '../../../Components/BannerCarrossel'
import ArtigoTop3 from '../Top3'
import { useEffect } from 'react'

import { GetArtigoByUrl } from '../../../Service/ApiService'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faFacebook, faTwitter, faPinterest } from '@fortawesome/free-brands-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Header = (props) => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const [artigo, setArtigo] = useState()
  const [status, setStatus] = useState({ id: 1, message: '' })

  library.add(fab, faSpinner)

  useEffect(() => {
    async function load() {
      var res = await GetArtigoByUrl(tokenApp, props.match.params.titulo)
      if (res.result) {
        setArtigo(res)
        setStatus({ id: 2, message: '' })
      } else {
        setStatus({ id: 3, message: res.message })
      }
    }
    load()
  }, [props.match.params.titulo])

  return (
    <Wrapper>
      <BannerCarrossel />
      <Container>
        {
          {
            1: (
              <Status>
                <FontAwesomeIcon icon={['fas', 'spinner']} pulse fixedWidth size="4x" />
              </Status>
            ),
            2: (
              <Fragment>
                <Image src={artigo?.urlImage}></Image>
                <Title>
                  <span>{artigo?.titulo}</span>
                </Title>
                <Content>
                  <div dangerouslySetInnerHTML={{ __html: artigo?.conteudo }} />
                </Content>
                <Share>
                  <span>Compartilhe </span>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon width={64} icon={faFacebook} />
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${artigo?.titulo}&url=${window.location.href}&related=`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon width={64} icon={faTwitter} />
                  </a>
                  <a
                    href={`http://pinterest.com/pin/create/button/?url=${window.location.href}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon width={64} icon={faPinterest} />
                  </a>
                </Share>
              </Fragment>
            ),
            3: (
              <Status>
                <h2>{status.message}</h2>
              </Status>
            ),
          }[status.id]
        }
      </Container>
      <ArtigoTop3 Titulo={['Outros artigos']} />
    </Wrapper>
  )
}

export default Header
