import styled from "styled-components";
import colors from "../../Theme/colors";
import {sizing, styling} from '../../Theme/fonts';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 10px auto;
    font-family: ${styling.NORMAL},sans-serif;

    @media(max-width: 688px) {
        flex-direction: column;
        justify-content: center;

    }
`;
export const Infos = styled.div`
    width: 52%;
    
    @media(max-width: 688px) {
        width: 100%;
    }
`;
export const Title = styled.h1`
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-weight: 400;
    font-size: 28px;
    
    margin: 15px 0 5px 0;
`;
export const Texto = styled.p`
    line-height: 25px;
    font-size: ${sizing.SMALL}px;
    span{ font-weight: 700; font-size: ${sizing.LARGE}px}
    
    margin: 0 0 0 10px;
`;
export const Video = styled.div`
    width: 40%;
    text-align:center;
    @media(max-width: 688px) {
        width: 100%;
    }
`;
export const Img = styled.div`    
    background-size: cover;
    margin: 20px auto;
    img:first-child{
        width: 70vw;
        max-width: 992px;
        @media(max-width: 688px) {
            width: 100vw;

        }
    }
`;
 
export const FrameYoutube = styled.iframe`
    width: 100%;
    max-width: 560px;
    aspect-ratio: 16 / 9;
    border: none;
`;
