const _URL_BASE = 'https://localhost:44323/api'
const URL_BASE = 'https://guiadaconstrucao-api-test.azurewebsites.net/api'

const KEY_APP = '4e5ce889-6a20-44a2-a9a9-d89db80d89d3'

export const GetTokenApp = async () => {
  const req = await fetch(`${URL_BASE}/Participante/TokenApp?KeyApp=${KEY_APP}`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  })
  const json = await req.json()
  return json
}

export const GetAssociados = async (token, categoriaId, filtro, estado, cidade) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  let filtros = ''
  if (categoriaId !== undefined) {
    if (filtros !== '') filtros = filtros.concat('&')
    filtros = filtros.concat('categoriaId=', categoriaId)
  }
  if (filtro !== undefined) {
    if (filtros !== '') filtros = filtros.concat('&')
    filtros = filtros.concat('filtro=', filtro)
  }

  const req = await fetch(`${URL_BASE}/Participante/GetFTLList`, {
    method: 'POST',
    headers: _headers,
    body: JSON.stringify({ categoriaId, filtro, estado, cidade }),
  })
  const json = await req.json()
  return json
}

export const GetAssociadoById = async (token, participanteId) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/GetFTLById?id=${participanteId}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetCupons = async (token) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Cupom/GetFTLList`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetCupomById = async (token, cupomId) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Cupom/GetFTLById?id=${cupomId}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetArtigos = async (token) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Artigo/GetForTimeline`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetArtigoByUrl = async (token, url) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Artigo/GetArtigoByUrl?Url=${url}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetEstados = async (token) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/GetEstados`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}
export const GetCidades = async (token, estado) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/GetCidades?estado=${estado}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const PostContato = async (token, values) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/ContatoSite`, {
    method: 'POST',
    headers: _headers,
    body: JSON.stringify(values),
  })
  const json = await req.json()
  return json
}

export const GetCategorias = async (token) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Categoria/GetCatsForApp`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const CatchCupom = async (token, cupomId) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Cupom/CatchCupom?cupomId=${cupomId}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const LoginUsuario = async (token, email, senha) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/Login`, {
    method: 'POST',
    headers: _headers,
    body: JSON.stringify({ email: email, senha: senha }),
  })
  const json = await req.json()

  return json
}

export const GetMeuCupom = async (token, cupClienteId) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Cupom/GetMeuCupom?cupClienteId=${cupClienteId}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetGeocode = async (address) => {
  const API_KEY = 'AIzaSyDXbfITOPFOgXU2JJDGd0dQNyLx9znIp3g'
  const req = await fetch(`https://maps.googleapis.com/maps/api/geocode/json`, {
    params: {
      address: address,
      key: API_KEY,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
    })
    .catch((error) => {
      console.log(error)
    })
  return req
}

export const PostOrcamento = async (token, values) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/Orcamento`, {
    method: 'POST',
    headers: _headers,
    body: JSON.stringify(values),
  })
  const json = await req.json()
  return json
}

export const GetAnuncios = async (token, participanteId) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Anuncio/GetAnuncios?ParticipanteId=${participanteId}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetAnunciosForBanner = async (token) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Anuncio/GetForApp`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const GetCep = async (token, endCep) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/GetCep?EndCEP=${endCep}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}

export const ParticipanteCadastrar = async (token, values) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/Cadastrar`, {
    method: 'POST',
    headers: _headers,
    body: JSON.stringify(values),
  })
  const json = await req.json()
  return json
}

export const AddFavorito = async (token, participanteId) => {
  const _headers = { 'content-type': 'application/json', Authorization: `Bearer ${token}` }
  const req = await fetch(`${URL_BASE}/Participante/AddFavorito?participanteId=${participanteId}`, {
    method: 'GET',
    headers: _headers,
  })
  const json = await req.json()
  return json
}
