import styled from 'styled-components'

export const Wrapper = styled.div``
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media (max-width: 688px) {
    height: 200px;
    justify-content: flex-start;
  }
`
export const Imgs = styled.div`
  width: 20%;
  height: 500px;
  overflow-y: auto;
  img {
    width: 75%;
    transition: transform 0.5s ease;
    margin: 10px 0;
    border-radius: 15px;
    object-fit: contain;

    :hover {
      transform: scale(1.06);
    }
  }

  @media (max-width: 688px) {
    height: 185px;
  }
`
export const ImgMain = styled.img`
  width: 80%;
  max-height: 450px;
  transition: transform 0.5s ease;
  object-fit: contain;
  padding: 10px;
  :hover {
    transform: scale(1.02);
  }
`
