import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts'

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  font-family: ${styling.NORMAL};

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const ContainerAnuncios = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 35px 10px 40px 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 688px) {
    text-align: center;
  }
`

export const ContainerCupons = styled.div`
  width: 100%;
  padding: 35px 20px;
  background-color: rgba(0, 0, 0, 0.06);
`
export const Title = styled.h2`
  color: ${colors.BLACK};
  font-size: 28px;
  font-weight: normal;

  span {
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-weight: bold;
  }
  @media (max-width: 688px) {
    margin: 10px auto;
    text-align: center;
  }
`

export const CardBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const Banner = styled.div`
  width: 100%;
  height: 200px;
  margin: 20px 0;
  background-color: ${colors.GRAY};
  font-family: ${styling.NORMAL};
  position: relative;

  display: flex;
  @media(max-width:640px){
    flex-direction: column ;
    align-items: center;
    justify-content: flex-start;
    margin-top: 25px;
  }
`
export const Img = styled.img`
  width: 60%;
  height: 100%;
  object-fit: cover;
  @media(max-width: 640px){
    width: 85%;
    margin-top: -15px;
    max-height: 120px;
  }
`
export const TextoBanner = styled.div`
  position: absolute;
  width: calc(40% - 70px);
  height: calc(100% - 40px);
  background-color: ${colors.WHITE};
  border-radius: 5px;
  top: 20px;
  right: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: normal;
    font-size: ${sizing.LARGER}px;
    width: calc(100% - 42px);
  }

  @media(max-width: 1024px){
    width: 40%;
  }

  @media(max-width:640px){
    position: relative;
    width: 80%;
    right: 0;
    border: 1px solid ${colors.GRAY};
    padding: 8px;
  }
`
export const Button = styled.button`
  width: 80%;
  border: none;
  border-radius: 25px;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  padding: 8px;
  font-family: ${styling.NORMAL};
  font-size: ${sizing.LARGE}px;
  font-weight: bold;

  a {
    text-decoration: none;
    color: ${colors.WHITE};
  }

  :hover {
    background-color: ${colors.GREEN};
    cursor: pointer;
  }
`
