import React from 'react'
import BannerCarrossel from '../../Components/BannerCarrossel'
import { Wrapper, Container, Title, Content } from './styles'

const QuemSomos = () => {
  return (
    <Wrapper>
      <BannerCarrossel />
      <Container>
        <Title>Política de Privacidade e Termos de Uso</Title>
        <Content>
          <p>
            <strong>Considerações</strong>
          </p>
          <p>O Guia da Construção é um provedor de serviço hospedado sob o domínio guiadaconstrucao1.com.br.</p>
          <p>
            Atua hospedando gratuitamente conteúdo de terceiros, ou seja, anúncios virtuais postados por seus usuários
            do segmento da construção civil.
          </p>
          <p>
            Desta forma, o usuário publica gratuitamente um anúncio no site do Guia da Construção com os dados iniciais
            para contato eletrônico, telefone e endereço. Opcionalmente os anunciantes podem dar mais destaque aos seus
            anúncios contratando os serviços específicos de acordo com os planos disponíveis.
          </p>
          <p>
            Por zelar pela qualidade das informações disponíveis no portal, o Guia da Construção, tem a obrigatoriedade
            de manter atualizadas as informações de todos os assinantes pagantes. Já os assinantes gratuitos devem
            sempre que necessário, informar ao Guia da Construção eventuais mudanças no anúncio. Entretanto, tal cuidado
            não torna o Guia da Construção, responsável pela veracidade dos dados especificados pelos anunciantes, nem
            pela qualidade e demais detalhes daquilo que é ofertado. O Guia da Construção não participa e/ou interfere,
            de qualquer forma, da negociação e/ou efetivação de quaisquer transações, serve apenas como um facilitador
            entre o consumidor interessado e o anunciante.
          </p>

          <p>
            <strong>ACEITE</strong>{' '}
          </p>
          <p>
            1.1 O Guia da Construção, oferece ao ao usuário, um conjunto de recursos online para o uso da plataforma de
            classificado virtual, a utilização dos Serviços implica na compreensão, aceitação e vinculação automática do
            Usuário aos termos e compromissos com o site. Ao fazer uso de quaisquer dos Serviços oferecidos, o Usuário
            concorda em respeitar e seguir todas e quaisquer diretrizes dispostas.
          </p>
          <p>
            1.2 Caso tenha alguma objeção a quaisquer itens, ou, caso fique insatisfeito com os Serviços oferecidos pela
            plataforma, por qualquer motivo que seja, o usuário poderá cessar o uso imediatamente, sem ônus.
          </p>
          <p>
            1.3 Este termo de compromisso poderá sofrer alterações periódicas, seja por questões legais ou estratégicas
            do Guia da Construção. O Usuário desde já concorda e reconhece que é de sua única e inteira responsabilidade
            a verificação periódica destes termos e compromissos. O site Guia da Construção, poderá, por mera
            liberalidade, informar ao Usuário sobre alterações significativas, através de avisos na página principal de
            seu site.
          </p>

          <p>
            <strong>POLITICA DE PRIVACIDADE E DIVULGAÇÃO DE INFORMAÇÕES NO SITE DO GUIA DA CONSTRUÇÃO</strong>{' '}
          </p>
          <p>
            2. O usuário deste site, autoriza o Guia da Construção, a seu critério, preservar, armazenar todos os
            anúncios e/ou conteúdos submetidos ao site, bem como todos os seus dados pessoais, a exemplo de endereços de
            e-mail, endereços de IP, informações de data e horário de acesso, entre outras informações.
          </p>
          <p>
            2.1 O Usuário autoriza o Guia da Construção a informar e/ou divulgar estes dados em caso de exigência legal
            ou se razoavelmente necessárias para: cumprir com o devido processo legal; responder a alegações de suposta
            violação de direitos de terceiros e de divulgação indevida de informações para contato de terceiros (por
            exemplo, número de telefone, endereço residencial), e para proteger os direitos, a propriedade ou a
            segurança de terceiros ou do próprio site guiadaconstrucao1.com.br e de seus usuários.
          </p>
          <p>
            2.2 O Guia da Construção poderá utilizar empresas de processamento de cartões de crédito e/ou de gestão de
            meios de pagamentos online terceirizadas, assim como empresas para monitorar o tráfego do site, que, em
            alguns casos, poderão armazenar e coletar informações e dados do usuário.
          </p>
          <p>
            2.3 A utilização do site implica no consentimento do usuário para coleta, armazenamento e uso das
            informações pessoais fornecidas e suas atualizações, dados de tráfego, endereços IP, entre outros.
          </p>
          <p>
            2.4 O Guia da Construção poderá utilizar cookies que poderão ser utilizados independentemente de cadastro do
            Usuário, que podem coletar informações como data e horário de acesso, histórico de navegação, preferências e
            nome do usuário.
          </p>
          <p>
            2.5 O Site poderá, eventualmente, conter links para sites de terceiros. O Guia da Construção não se
            responsabiliza pelo conteúdo ou pela segurança das informações do usuário quando acessar sites de terceiros.
            Tais sites podem possuir suas próprias políticas de privacidade quanto ao armazenamento e conservação de
            informações pessoais, completamente alheias ao Guia da Construção.
          </p>
          <p>
            2.6. Ao publicar um anúncio no site do Guia da Construção (www.guiadaconstrucao1.com.br), o usuário
            compreende que os anúncios e o conteúdo são públicos e acessíveis a terceiros, podendo ser listados nos
            resultados de ferramentas de pesquisa como Yahoo!, MSN, Google e outros e no cache dessas ferramentas de
            pesquisa, em feeds e outros websites por acordos comerciais entre o Guia da Construção e outras prestadoras
            de serviço. O Usuário compreende que é de responsabilidade de cada uma dessas ferramentas de pesquisa,
            websites ou recursos RSS manter seus índices e cache atualizados e remover o Conteúdo de seus índices e
            cache, pois não é possível que o Guia da Construção exerça influência sobre eles.
          </p>
          <p>
            2.7. O Guia da Construção, poderá utilizar as informações e/ou dados fornecidos e/ou coletados pelo usuário
            para: Acompanhar as atividades do Usuário, utilizando palavras chave de pesquisas e novos anúncios para
            administrar mais efetivamente o tráfego no Site; Prestar serviços ao Usuário; Criar e administrar Contas de
            Usuário; e Prestar assistência técnica.
          </p>
          <p>
            2.8 É reservado ao Guia da Construção, o direito de bloquear o acesso ao Site de Usuários alocados em alguns
            países ou a seu critério.
          </p>
          <p>
            2.9 O Guia da Construção se reserva o direito de reter informações pelo período que entender necessário para
            o bom cumprimento de seus negócios, mesmo após o encerramento da conta do usuário, salvo manifestação
            expressa do usuário em sentido contrário.
          </p>
          <p>
            2.10. As informações e dados cadastrais do Usuário podem ser protegidas por senha e nome de usuário
            exclusivos, pessoais e intransferíveis, que não devem ser divulgadas, quer pelo Guia da Construção, quer
            pelo próprio Usuário.
          </p>
          <p>
            2.11. O Usuário desde já declara estar ciente que o Guia da Construção, não assume nenhuma responsabilidade
            em caso de roubo, perda, alteração ou uso indevido de suas informações pessoais e do conteúdo como um todo,
            inclusive na hipótese de informações fornecidas a prestadores de serviços terceirizados ou a outros
            usuários.
          </p>
          <p>
            2.12 O nome e e-mail do usuário somente serão divulgados em seus comentários quando neles inclusos pelo
            próprio Usuário.
          </p>
          <p>
            2.13 O Guia da Construção recomenda que o usuário não divulgue nestas ferramentas, ou em qualquer outro
            conteúdo, informações que o usuário não deseja que sejam públicas.
          </p>

          <p>
            <strong>POLÍTICA DE USO</strong>{' '}
          </p>
          <p>
            3.1. O Usuário está ciente de que todos os anúncios disponíveis no site são de sua responsabilidade e
            garantem tratar-se de anúncios dentro da área da construção civil, e que pode haver monitoramento ou
            verificação prévia do Guia da Construção, que interfere nos anúncios veiculados por assinantes pagantes e
            não pagantes, ou mediante solicitação dos anunciantes de forma geral. O Usuário também concorda que ao
            utilizar o site poderá se expor a conteúdo eventualmente ofensivo, inexato, enganoso ou, de qualquer outro
            modo, censurável ou que esteja em desacordo com os seus princípios religiosos ou políticos.
          </p>
          <p>
            3.2 O site do Guia da construção e o conteúdo nele disponível poderão conter links para outros websites sem
            qualquer relação ou ligação com o Guia da Construção. Desta forma, não garantimos a precisão ou
            autenticidade das informações contidas em quaisquer destes websites. O acesso e/ou a inserção de link
            através do site é realizada por livre e inteira opção do Usuário e sob sua exclusiva responsabilidade.
          </p>
          <p>
            3.3. O usuário concorda que deve avaliar e assumir quaisquer riscos associados a qualquer Conteúdo e/ou
            Anúncio submetido ao site. Sob nenhuma circunstância, o Guia da Construção será responsável, de qualquer
            forma que seja, pelo Conteúdo e/ou pelas perdas ou danos incorridos em função do uso, submissão e acesso de
            qualquer Conteúdo e/ou Anúncio listado, enviados por e-mail ou, de qualquer outro modo, disponibilizados
            através do site.
          </p>
          <p>
            3.4. O usuário reconhece que o Guia da Construção terá o direito (mas não a obrigação) de, a seu exclusivo
            critério, recusar, excluir ou mover qualquer conteúdo e/ou anúncio disponibilizado no site, seja por
            violação ou por quaisquer outras razões de ordem ética, legal ou moral.
          </p>
          <p>
            3.5 O usuário é o único responsável por todo o anúncio e/ou conteúdo de sua autoria ou por ele divulgado,
            transmitido por e-mail ou associado a link disponibilizado no/ou através do site; assim como pelo seu uso,
            divulgação ou publicação, devendo o usuário manter o Guia da Construção, seus funcionários, parceiros e
            controladores livres e indenes de toda e qualquer consequência advinda de seus atos/omissões, inclusive em
            relação a terceiros.
          </p>
          <p>
            3.6 Com relação ao anúncio e/ou conteúdo divulgado, transmitido ou associado a link no site, o usuário
            declara que é o titular dos direitos e/ou possui as autorizações necessárias para divulgar o conteúdo e
            permitir que o Guia da Construção o utilize e/ou eventualmente o formate para melhor disposição no site, e
            possui o consentimento expresso, licença e/ou permissão de toda e qualquer pessoa identificável no conteúdo,
            seja ela para usar seu nome e/ou sua imagem. 3.7 O Guia da Construção não permite, e o usuário desde já
            aceita e concorda, a submissão ao site de anúncios e/ou conteúdos de qualquer forma relacionados a
            atividades que desrespeitem direitos de propriedade intelectual, ou qualquer outra violação a direitos de
            terceiros, e poderá remover todo o Anúncio e/ou Conteúdo inadequado, quando devidamente notificada de que o
            mesmo viola direitos de terceiros ou a legislação brasileira.
          </p>
          <p>
            3.8. O Guia da Construção se reserva o direito de remover o Anúncio e/ou qualquer Conteúdo submetido ao Site
            sem aviso prévio. O Guia da Construção, PODERÁ, A SEU EXCLUSIVO CRITÉRIO, SUSPENDER OU CANCELAR, DE QUALQUER
            FORMA QUE SEJA, O CADASTRO E/OU ACESSO DO USUÁRIO AO SITE EM CASO DE INFRAÇÃO A QUALQUER DAS DISPOSIÇÕES
            DESTES TERMOS. O Guia da Construção, também se reserva o direito de decidir, a seu único e exclusivo
            critério, quais Conteúdos e/ou Anúncios entende como inadequados.
          </p>
          <p>
            3.10 Poderão, a critério exclusivo do Guia da Construção e, ser cobradas taxas para postagem de Conteúdo
            e/ou Anúncio em determinadas áreas do site.
          </p>
          <p>
            3.11. Eventuais taxas pagas não serão reembolsadas em caso de remoção do Conteúdo e/ou Anúncio do Site por
            qualquer motivo que seja.
          </p>

          <p>
            <strong>POLÍTICA ANTI-SPAN</strong>{' '}
          </p>
          <p>
            4.1 O Usuário está ciente de que o envio, por e-mail ou pelos sistemas do Guia da Construção de mensagens
            não solicitadas aos endereços de e-mail do site do Guia da Construção e/ou de sua base de dados é
            expressamente proibido, e que o uso não autorizado dos sistemas representa uma violação a estes termos e à
            legislação brasileira. Tais violações podem sujeitar o remetente e seus agentes às penalidades cíveis e
            criminais aplicáveis.
          </p>

          <p>
            <strong>LIMITAÇÃO E TÉRMINO DO SERVIÇO</strong>{' '}
          </p>
          <p>
            5.1. Os usuários reconhecem que o Guia da Construção pode estabelecer limites referentes ao uso e acesso do
            site, inclusive quanto ao número máximo de dias que um anúncio será exibido no site, o número e tamanho
            máximo de textos, mensagens de e-mail ou outros Anúncios e/ou Conteúdos que possam ser transmitidos ou
            armazenados no site.
          </p>
          <p>
            5.2. O Usuário reconhece que o Guia da Construção não é obrigado a manter ou armazenar qualquer Anúncio e/ou
            Conteúdo no Site, podendo, a qualquer momento e por qualquer motivo, modificar ou descontinuar o Serviço,
            sem aviso prévio, e sem que qualquer obrigação seja criada com os usuários ou com terceiros por tais
            modificações, suspensões ou descontinuidade do Serviço.
          </p>
          <p>
            5.3. O usuário concorda que o Guia da Construção, a seu critério, pode excluir ou desativar sua conta,
            bloquear seu e-mail ou endereço de IP, ou, de outro modo, encerrar seu acesso ou uso do Site (ou parte dele)
            imediatamente, sem aviso prévio, além de remover qualquer Anúncio e/ou Conteúdo do site.
          </p>
          <p>
            5.4. O usuário reconhece ainda, que o Guia da Construção não possui nenhuma obrigação com ele ou com
            terceiros pelo término de seu acesso ao site e concorda em não tentar acessá-lo novamente.
          </p>

          <p>
            <strong>RESPONSABILIDADE DOS USUÁRIOS</strong>{' '}
          </p>
          <p>6.1. O usuário, declara ser maior de 18 anos, e ser plenamente capaz para se vincular a este site.</p>
          <p>
            6.2. Menores de idade não devem enviar informações pessoais, tais como endereço de e-mail, nome e/ou
            informação para contato ao Guia da Construção. Apenas os pais ou responsáveis legais estão autorizados a
            entrar em contato com o Guia da Construção.
          </p>
          <p>
            6.3. O usuário, sendo pessoa jurídica, o fará na figura do seu representante legal, respeitando as mesmas
            disposições do item acima.
          </p>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default QuemSomos
