import styled from 'styled-components'
import colors from '../../../Theme/colors'
import { styling } from '../../../Theme/fonts'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1050px;
  background-color: #fff;
  height: auto;
  font-family: ${styling.NORMAL};
`

export const Container = styled.div`
  padding: 20px 20px 0 20px;
`

export const Image = styled.img`
  width: 60%;
  aspect-ratio: 16 / 8;
  background-color: grey;
  transition: transform 1s ease;
  margin-left: 20%;
  object-fit: cover;
`
export const Title = styled.h2`
  color: ${colors.BLACK};
  font-size: 28px;
  font-weight: normal;
  span {
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-weight: bold;
  }
  @media (max-width: 688px) {
    margin: 10px auto;
    text-align: center;
  }
`

export const Content = styled.div`
  font-family: ${styling.NORMAL};
  margin: 0 0 20px 10px;
  p {
    margin: 4px 0;
  }
`
export const Share = styled.div`
  width: calc(100% -40px);
  padding: 20px 10px;
  background-color: ${colors.YELLOW_GUIA};
  color: ${colors.WHITE};
  border-radius: 5px;

  a {
    color: ${colors.WHITE};
  }
  span:first-child {
    margin-right: 5px;
  }

  svg:nth-child(n) {
    margin: 0 4px;
  }

  @media (max-width: 688px) {
    text-align: center;
  }
`
export const Status = styled.div`
  text-align: center;
  margin-top: 20px;
`
