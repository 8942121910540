import { React } from 'react'
import { Wrapper, Container, Title, Card, FrameYoutube } from './styles'

const Component = () => {
  return (
    <Wrapper>
      <Title>Depoimentos</Title>
      <Container>
        <Card>
          <FrameYoutube
            src="https://www.youtube.com/embed/h81Aqer22Ks"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></FrameYoutube>
        </Card>
        <Card>
          <FrameYoutube
            src="https://www.youtube.com/embed/t3WVJPmhMso"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></FrameYoutube>
        </Card>
      </Container>
    </Wrapper>
  )
}

export default Component
