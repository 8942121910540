import React, { useState, useEffect, Fragment } from 'react'
import { Wrapper, Container, Imgs, ImgMain } from './style'

const GalleryImages = ({ anuncios }) => {
  const [values, setValues] = useState()
  const [imageMain, setImageMain] = useState({ urlImage: '' })

  useEffect(() => {
    setValues(anuncios)
    if (anuncios?.length > 0) setImageMain(anuncios[0])
  }, [anuncios])

  function changeImg(e) {
    let imgCurrent = e.target.src
    setImageMain({ urlImage: imgCurrent })
  }

  console.log(values)
  return (
    <Wrapper>
      <Container>
        {values !== undefined && (
          <Fragment>
          <Imgs>
            {values.map((item, index) => (
              <img key={index} src={item.urlImage} onClick={(e) => changeImg(e)} alt="Gallery"></img>
            ))}
          </Imgs>
          <ImgMain src={imageMain.urlImage} />
        </Fragment>
        )}
      </Container>
    </Wrapper>
  )
}

export default GalleryImages
