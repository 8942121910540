import styled from 'styled-components'
import { sizing, styling } from '../../Theme/fonts'
import colors from '../../Theme/colors'

import InputCep from '../../Components/InputCep'
import InputTel from '../../Components/InputTelefone'

export const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  font-family: ${styling.NORMAL};

  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: ${sizing.LARGE}px;
  }
`
export const TitleHeader = styled.h1`
  margin: 20px 0 0 0;
  text-transform: uppercase;
`

export const BoxProgress = styled.div`
  width: Calc(100% - 40px);
  padding: 0 20px;

  p {
    font-size: ${sizing.LARGE}px;
  }

  @media (max-width: 688px) {
    width: Calc(100% - 10px);
    padding: 0 5px;
  }
`
export const Title = styled.h2`
  color: ${colors.BLACK};

  span {
    color: ${colors.COLOR_DEFAULT_BUTTON};
  }
`
export const ListBox = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 20px;

  @media (max-width: 688px) {
    display: block;
    div {
      width: 100%;
    }
  }
`
export const List = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const ListItem = styled.div`
  display: flex;
  padding: 4px;
  width: 32%;

  label {
    white-space: pre-wrap;
  }
`
export const InputCheck = styled.input`
  margin-right: 12px;
  :checked {
    background-color: ${colors.COLOR_DEFAULT_BUTTON};
  }
`
export const TextArea = styled.textarea`
  width: Calc(100% - 24px);
  background-color: ${colors.VIOLET_BACKGROUND};
  padding: 12px;
  resize: none;
  outline: none;
  border: 1px solid #dddddd;
`
export const DetailsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* 
  div:nth-child(1) {
    width: 48%;
    display: flex;
    flex-direction: column;
  }
  div:nth-child(2) {
    width: 48%;
    display: flex;
    flex-direction: column;
  } */
`
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 48%;
  width: 100%;
  padding: 5px;

  @media (max-width: 688px) {
    flex: 0 0 98%;
  }
`

export const Select = styled.select`
  width: 100%;
  min-height: 40px;
  padding: 12px;
  outline: none;
  border: 1px solid #dddddd;
`

export const Label = styled.label`
  margin: 20px 0 8px;
  font-size: ${sizing.SMALL}px;
`
export const InputCEP = styled(InputCep)`
  width: 96%;
  min-height: 40px;
  padding-left: 12px;
  outline: none;
  border: 1px solid #dddddd;
`
export const InputTelefone =  styled(InputTel)`
  width: 96%;
  min-height: 40px;
  padding-left: 12px;
  outline: none;
  border: 1px solid #dddddd;
`
export const Input = styled.input`
  width: 96%;
  min-height: 40px;
  padding-left: 12px;
  outline: none;
  border: 1px solid #dddddd;
`
export const BtnArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
`
export const ButtonPrev = styled.button`
  width: 20%;
  border: 1px solid black;
  background-color: ${colors.WHITE};
  color: ${colors.BLACK};
  border-radius: 25px;
  padding: 8px;
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALL}px;
  font-weight: bold;
  margin: 10px 0;
  float: left;
  cursor: pointer;

  @media (max-width: 688px) {
    width: 50%;
  }
`
export const ButtonNext = styled.button`
  width: 20%;
  border: none;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  color: ${colors.WHITE};
  border-radius: 25px;
  padding: 8px;
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALL}px;
  font-weight: bold;
  margin: 10px 0;
  float: right;
  cursor: pointer;
  margin-left: auto;
  @media (max-width: 688px) {
    width: 50%;
  }
`
