export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');

      if (serializedState === null) {
        return {
          TokenApp: '',
          TokenType: '',
          Par_Nome:'',
          Par_UsuarioId:'',
          Par_ParticipanteId:'',
          Par_Tipo:'',
      };
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 

  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (err) {
      console.error(err);
      // ignore write errors
    }
  };