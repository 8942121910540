import React, { Fragment } from 'react'
import { useHistory } from 'react-router'
import BannerCarrossel from '../../Components/BannerCarrossel'
import { ContainerWrapper, ContainerInfo, Titulo, Textos, Texto, Button, Anuncio, Contato, Atuacao, BoxInfo, List, Img, Depoimento, FrameYoutube } from './style'
//import imagem from '../../Assets/Images/quemsomos.jpg'

const QuemSomos = () => {

    const history = useHistory();

    const regioes = [
        { regiao: 'Piracicaba e região(São Pedro, Águas de São Pedro, Saltinho, Charqueada, Rio das Pedras e Capivari)' },
        { regiao: 'Americana e região(Americana, Sta Bárbara, Nova Odessa e Sumaré)' },
        { regiao: 'Limeira e região(Limeira, Iracemápolis, Cosmópolis e Arthur Nogueira)' },
        { regiao: 'Rio Claro e região(Rio Claro, Cordeirópolis, Sta Gertrudes, Ajapi e Ipeúna)' },
        { regiao: 'Araras e região(Araras, Conchal, Leme e Engenheiro Coelho)' },
        { regiao: 'Indaiatuba e região(Indaiatuba, Itu, Salto e Porto Feliz)' },
        { regiao: 'Jundiaí e região(Jundiaí, Louveira, Itupeva, Cabreúva, Campo Limpo, Várzea, Jarinú, Barueri, Santana do Parnaíba e Cajamar)' },
        { regiao: 'Campinas e Região(Campinas, Hortolândia, Paulínia, Jaguariúna, Vinhedo, Valinhos, Holambra, Monte Mór, Pedreira, Santao Antonio de Posse e Amparo)' },
        { regiao: 'Sorocaba e região(Sorocaba, Votorantim, Araçoiaba da Serra, Salto de Pirapora)' },
        { regiao: 'Itapetininga e região(Itapetinga, Boituva, Tietê, Tatuí, Cerquilho, Cesario Lange)' }
    ]

    return (
        <Fragment>
            <BannerCarrossel />
            <ContainerWrapper>
                <ContainerInfo>
                    <Titulo>Quem somos</Titulo>
                    <Textos>
                        <Texto>
                            <p>Somos uma empresa especializada em captação de obras e orçamentos! </p>
                            <p>Estamos no mercado há mais de 25 anos, somos pioneiros no segmento e crescemos tanto graças ao nosso profissionalismo e credibilidade com as informações prestadas às empresas participantes! </p>
                            <p>Entregamos sua propaganda na pasta do Guia da Construção, em mãos, para os responsáveis das obras em andamento. Nada melhor do que ir direto a pessoa que está construindo e que precisa do seu produto, concorda? </p>
                            <p>Temos uma equipe com mais de 10 profissionais especializados em captação de obras trabalhando para a sua empresa, trazendo para sua equipe de vendas, relatórios completos das obras dos bairros e condomínios da sua cidade. </p>
                            <p>Durante a semana os captadores registram em média, 20 obras em andamento de cada região de atuação. Trabalhamos com 10 regiões! </p>
                            <p>São 200 obras captadas por semana, além de um CRM completo para sua equipe de vendas trabalhar com as informações!</p>
                        </Texto>

                        <Texto>
                            <p>Sua empresa terá um login e senha de acesso exclusivo para o site do Guia da Construção (www.guiadaconstrucao1.com.br) onde sua equipe de vendas poderá entrar em contato com o responsável por compras de material de cada obra, além de proprietários, empreiteiros, construtores, engenheiros e arquitetos, visualizar a fachada da obra, selecionar por data de captação, tamanho da obra em M2, estágio das obras e criar parcerias de sucesso. </p>
                            <p>Temos uma equipe de telemarketing entrando em contato com as obras em andamento captadas na semana, e assim que o responsável ou proprietário solicitar um orçamento específico da sua empresa, imediatamente será encaminhado um aviso para o seu e-mail! </p>
                            <p>Tudo isso por um investimento muito baixo, pois estamos focados no seu resultado e na parceria de longa data! </p>
                            <p>Solicite a visita de um de nossos representantes comerciais sem compromisso e conheça a nossa história!</p>
                        </Texto>
                    </Textos>
                </ContainerInfo>
                <Anuncio>
                    <Contato>
                        <h2>Seja um associado, amplie suas vendas e chegue na frente de seus concorrentes!</h2>
                        <Button onClick={() => history.push('/contato')}>Fale Conosco</Button>
                    </Contato>
                </Anuncio>
                <Atuacao>
                    <Titulo>Regiões onde atuamos</Titulo>
                    <BoxInfo>
                        <List>{regioes.map((r, i) => <li key={i}>- {r.regiao}</li>)}</List>
                        <Img ></Img>
                    </BoxInfo>
                </Atuacao>
                <Depoimento>
                    <FrameYoutube src="https://www.youtube.com/embed/aka_Feevcro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></FrameYoutube>
                </Depoimento>
            </ContainerWrapper>
        </Fragment>

    )
}

export default QuemSomos
