import React from 'react';
import ReactDOM from 'react-dom';

import './Assets/custon.css';
import './Assets/base.css';

import { Provider } from 'react-redux';
import storeApp from './Reducers/storeApp';

import App from './App'

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={storeApp}>
    <App />
  </Provider>,
  rootElement
);
