import styled from 'styled-components'

export const Mapa = styled.div`
  width: 50%;
  min-height: 250px;

  @media(max-width:640px){
    width: 100%;
  }
`
