import styled from 'styled-components'
import colors from '../../../Theme/colors'
import { styling } from '../../../Theme/fonts'

export const Wrapper = styled.div``

export const Container = styled.div`
  width: calc(100% - 40px);
  padding: 5px 20px 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: ${styling.NORMAL};
`

export const Title = styled.h2`
  color: ${colors.BLACK};
  font-size: 28px;
  font-weight: normal;
  /*margin: 10px;*/
  padding-top: 10px;
  span {
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-weight: bold;
  }
  @media (max-width: 688px) {
    margin: 10px auto;
    text-align: center;
  }
`

export const CardBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const BtnMais = styled.a`
  margin: 0 auto;
  padding: 10px 20px;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  width: 100px;
  text-align: center;
  font-weight: bold;
  color: ${colors.WHITE};
  text-decoration: none;
`
