import { createStore } from 'redux';
import { loadState, saveState } from './localStorage'


function storeAppL (state = {
    TokenExpiration: '',
    TokenApp: '',
    TokenType: '',
    Par_NomeComp:'',
    Par_Nome:'',
    Par_UsuarioId:'',
    Par_ParticipanteId:'',
    Par_Tipo:'',
    Filtro_Busca: []
}
    , action){
    // eslint-disable-next-line default-case
    switch(action.type){
        case 'TokenExpiration':
            return { 
                ...state, 
                TokenExpiration: action.value
            };
        case 'TokenApp':
            return { 
                ...state, 
                TokenApp: action.value
            };

        case 'TokenType':
            return { 
                ...state, 
                TokenType: action.value
            };
        case 'Par_NomeComp':
            return { 
                ...state, 
                Par_NomeComp: action.value
            };
        case 'Par_Nome':
            return { 
                ...state, 
                Par_Nome: action.value
            };
        case 'Par_UsuarioId':
            return { 
                ...state, 
                Par_UsuarioId: action.value
            };
        case 'Par_ParticipanteId':
            return { 
                ...state, 
                Par_ParticipanteId: action.value
            };
        case 'Par_Tipo':
            return { 
                ...state, 
                Par_Tipo: action.value
            };
        case 'Filtro_Busca':
            return { 
                ...state, 
                Filtro_Busca: action.value
            };
        }
    return state;
}

const persistedState = loadState();
const store = createStore(storeAppL, persistedState);
store.subscribe(() => {
  saveState(store.getState());
});

export default store;