import { React } from 'react'
import { Wrapper, ContainerHeader, HeaderBody, Img, Menu, HeaderFooter } from './style'

import LogoGuia from '../../Assets/Images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'

const Header = () => {
  return (
    <Wrapper>
      <ContainerHeader>
        <HeaderBody>
          <a href="/">
            <Img src={LogoGuia}></Img>
          </a>
          <Menu>
            <a href="/quemsomos">INSTITUCIONAL</a>
            <a href="/comotrabalhamos">NOSSOS SERVIÇOS</a>
            <a href="https://www.facebook.com/guiadaconstrucaooficial/">
              <FontAwesomeIcon height={48} icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/guiadaconstrucaooficial/">
              <FontAwesomeIcon height={48} icon={faInstagram} />
            </a>
            <a href={process.env.REACT_APP_URLPAINEL}>
              <FontAwesomeIcon height={48} icon={faUserCircle} />
              <label> ASSINANTE</label>
            </a>
          </Menu>
        </HeaderBody>
        <HeaderFooter>
          <a href="/orcamentos">ORÇAMENTOS</a>
          <a href="/artigos">DICAS PARA SUA OBRA</a>
          <a href="/Cadastro">
            <strong>QUERO ANUNCIAR</strong>
          </a>
        </HeaderFooter>
      </ContainerHeader>
    </Wrapper>
  )
}

export default Header
