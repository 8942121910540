import styled from 'styled-components'
import colors from '../../../Theme/colors'

export const Wrapper = styled.div``

export const Container = styled.div`
  width: calc(100% - 40px);
  padding: 5px 20px 20px;
`

export const Title = styled.h2`
  color: ${colors.BLACK};
  font-size: 28px;
  font-weight: normal;
  margin: 10px;
  span {
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-weight: bold;
  }
  @media (max-width: 688px) {
    margin: 10px auto;
    text-align: center;
  }
`

export const CardBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
