import { React, Fragment, useEffect, useState } from 'react'

import { GetAssociados, GetCupons } from '../../Service/ApiService'

import {
  ContainerAnuncios,
  ContainerCupons,
  Container,
  Img,
  Title,
  CardBox,
  Banner,
  TextoBanner,
  Button,
} from './styles'

import CardEmpresa from '../../Components/CardHome/CardEmpresa/CardEmpresa'
import CardCupom from './CardCupom/index'
import BannerCarrossel from '../../Components/BannerCarrossel'
import FormSearch from '../../Components/FormPesquisa'
import CategoriaCarrossel from '../../Components/CategoriaCarrossel'
import { useDispatch, useSelector } from 'react-redux'
import Depoimentos from './Depoimentos'

import HomeBanner from '../../Assets/Images/homebanner.jpg'
import ArtigoTop3 from '../Artigos/Top3'
import Modal from '../../Components/ModalHome/index'
import { useHistory } from 'react-router-dom'
import useLoader from '../../Reducers/useLoader'

const Home = () => {
  const [anuncios, setAnuncios] = useState()
  const [cupons, setCupons] = useState()
  const [showModal, setShowModal] = useState(false)
  const tokenApp = useSelector((state) => state.TokenApp)
  const history = useHistory()
  const dispatch = useDispatch()
  const navigate = useHistory()
  const [loader, showLoader, hideLoader] = useLoader();

  useEffect(() => {
    showLoader()
    async function loadData() {
      setAnuncios(await GetAssociados(tokenApp))
      setCupons(await GetCupons(tokenApp))
      hideLoader()
    }

    loadData()
  }, [tokenApp])

  function onSearch(e) {
    dispatch({ type: 'Filtro_Busca', value: e })
    history.push('/associados')
  }

  const [infosModal, setInfosModal] = useState({
    nome: '',
    nmCat: '',
    descricao: '',
    telefone: '',
    urlLogo: '',
    urlSite: '',
    endereco: '',
  })
  const openModal = (loja) => {
    setInfosModal(loja)
    setShowModal(!showModal)
  }

  const openSingleLoja = (loja) => {
    //navigate.push(`/associado/${string_to_slug(loja.nome)}`, loja, '_blank');
    window.open(`/associado/${loja.participanteId}/${string_to_slug(loja.nome)}`);
  }

  const string_to_slug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
  }

  return (
    <Fragment>
      <BannerCarrossel />
      <FormSearch onSearch={(e) => onSearch(e)} />
      <CategoriaCarrossel></CategoriaCarrossel>
      <Modal showModal={showModal} setShowModal={setShowModal} infos={infosModal} />
      <Container>
        <ContainerAnuncios>
          <Title>
            Empresas em <span>Destaque</span>
          </Title>
          <CardBox>
            {anuncios?.map((loja, index) => {
              while (index < 4) {
                return (
                  <Fragment key={index}>
                    <CardEmpresa key={index} participanteId={loja.participanteId} onClick={(e) => openSingleLoja(e)} />
                  </Fragment>
                )
              }
            })}
          </CardBox>
        </ContainerAnuncios>
        <ContainerCupons>
          <Title>
                Cupons de <span>desconto</span>
          </Title>
          <CardBox>
            {cupons?.map((cupom, index) => {
              while (index < 4) {
                return <CardCupom key={index} cupomId={cupom.cupomId} />
              }
            })}
          </CardBox>
        </ContainerCupons>
      </Container>

      <ArtigoTop3 Titulo={['Dicas para sua ', <span>obra</span>]} />

      <Banner>
        <Img src={HomeBanner}></Img>
        <TextoBanner>
          <h2>CONHEÇA AS VANTAGENS DE SER UM ASSOCIADO!</h2>
          <Button>
            <a href="/ComoTrabalhamos">MAIS INFORMAÇÕES</a>
          </Button>
        </TextoBanner>
      </Banner>
      <Depoimentos />
      {loader}
    </Fragment>
  )
}

export default Home
