import styled from 'styled-components'
import colors from '../../../Theme/colors'
import { styling } from '../../../Theme/fonts'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${styling.NORMAL};
`
export const Container = styled.div`
  width: Calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
`

export const CardCupom = styled.div`
  width: calc(100% - 30px);
  max-width: 800px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(248, 148, 6, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin: 15px;

  @media (max-width: 688px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const Details = styled.div`
  @media (max-width: 688px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const Title = styled.h2``
export const Validade = styled.div`
  padding: 5px;
`
export const Regulamento = styled.div`
  font-size: 13px;
`

export const BoxNrCupom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${colors.YELLOW_GUIA};
  margin: 10px 0;
  width: 80%;
  @media (max-width: 688px) {
    width: 100%;
  }
`
export const LabelNrCupom = styled.h1`
  width: 70%;
  height: 20px;
  padding: 5px 0;
  font-weight: bold;
  font-size: 22px;
  text-align: center;
`
export const ButtonCopyNrCupom = styled.div`
  background-color: ${colors.YELLOW_GUIA};
  border: none;
  height: 42px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  * > {
    margin: auto auto;
  }
`

export const LabelCopyNrCupom = styled.text`
  font-weight: bold;
  padding: 6px;
  text-align: center;
  color: #fff;
`
