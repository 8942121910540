import { React, useEffect, useState } from 'react'
import { CardWrapper, CardHeader, Img, CardBody, Title, CardFooter, Button } from './style'
import { CatchCupom, GetCupomById } from '../../../Service/ApiService'

import { toast, Bounce } from 'react-toastify'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'
import img from './teste.png'
const CardCupom = ({ cupomId }) => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const tokenType = useSelector((state) => state.TokenType)
  const [values, setValues] = useState()

  async function onPegar(ev) {
    if (tokenType !== 'Cli') {
      window.location.href = '/Login?c=' + cupomId
    } else {
      PegarCupom()
    }
  }

  useEffect(() => {
    async function loadData() {
      if (cupomId) setValues(await GetCupomById(tokenApp, cupomId))
    }
    loadData()
  }, [cupomId])

  async function PegarCupom() {
    var result = await CatchCupom(tokenApp, cupomId)
    if (result.resultado) {
      window.location.href = '/MeuCupom/' + result.mensagem
    } else {
      toast(result.message, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      window.location.href = '/'
    }
  }

  return (
    <Fragment>
      {values && (
        <CardWrapper id={cupomId}>
          <CardHeader>
            <Img src={values.urlMD}></Img>
          </CardHeader>
          <CardBody>
            <Title>{values.titulo}</Title>
          </CardBody>
          <CardFooter>
            <Button onClick={onPegar}>Pegar Cupom</Button>
          </CardFooter>
        </CardWrapper>
      )}
    </Fragment>
  )
}
export default CardCupom
