import React from 'react'
import { Wrapper } from './style'

const ProgressBar = ({ percentage }) => {
  return (
    <Wrapper>
      <ul class="timeline" id="timeline">
        <li class={`li ${percentage > 0 ? 'complete' : ''}`}>
          <div class="status">
            <h4> Produtos E Serviços </h4>
          </div>
        </li>
        <li class={`li ${percentage > 1 ? 'complete' : ''}`}>
          <div class="status">
            <h4> Detalhes </h4>
          </div>
        </li>
        <li class={`li ${percentage > 2 ? 'complete' : ''}`}>
          <div class="status">
            <h4>Dados</h4>
          </div>
        </li>
        <li class={`li ${percentage > 3 ? 'complete' : ''}`}>
          <div class="status">
            <h4> Enviar </h4>
          </div>
        </li>
      </ul>
    </Wrapper>
  )
}

export default ProgressBar
