import React from 'react'
import { Wrapper, Card, Title, Input, Button } from './style'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast, Bounce } from 'react-toastify'

import { LoginUsuario, CatchCupom, AddFavorito } from '../../Service/ApiService'

const LoginRelatorio = (props) => {
  const [values, setValues] = useState({ email: '', senha: '' })
  const dispatch = useDispatch()
  const tokenApp = useSelector((state) => state.TokenApp)
  const params = new URLSearchParams(props.location.search)

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()

    if (values.email.length < 6) {
      toast('Informe um e-mail valido', {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      return
    }
    if (values.senha.length < 6) {
      toast('A senha é invalida', {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      return
    }

    const res = await LoginUsuario(tokenApp, values.email, values.senha)

    if (res.resultado) {
      if (res.participante.tipo !== 'C') {
        toast(
          'Identificamos que você não é um cliente, esse portal é para clientes, utilize o portal de associado/administrativo',
          { type: 'warning', transition: Bounce, closeButton: true, autoClose: 5000, position: 'top-right' }
        )
        return
      }

      dispatch({ type: 'TokenExpiration', value: res.expiration })
      dispatch({ type: 'TokenApp', value: res.token })
      dispatch({ type: 'TokenType', value: 'Cli' })
      dispatch({ type: 'Par_Nome', value: res.participante.nome })
      dispatch({ type: 'Par_NomeComp', value: 'Guia da Construção ' })
      dispatch({ type: 'Par_UsuarioId', value: res.participanteId })
      dispatch({ type: 'Par_ParticipanteId', value: res.participanteId })
      dispatch({ type: 'Par_Tipo', value: res.participante.tipo })

      if (params.get('c')) {
        PegarCupom(res.token)
      } else if (params.get('f')) {
        onFavorito(res.token)
      } else {
        window.location.href = '/'
      }
    } else {
      toast(res.message, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
    }
  }

  async function PegarCupom(token) {
    var result = await CatchCupom(token, params.get('c'))
    if (result.resultado) {
      window.location.href = '/MeuCupom/' + result.mensagem
    } else {
      toast(result.message, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      window.location.href = '/'
    }
  }

  async function onFavorito(token) {
    var response = await AddFavorito(token, params.get('f'))
    if (response.result) {
      window.location.href = '/associados?f=' + params.get('f')
    } else {
      toast(response.message, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
    }
  }

  return (
    <Wrapper>
      <Card>
        <Title>Acesso Restrito</Title>
        <p>Faça o login para acessar o sistema</p>

        <Input type="email" placeholder="Usuário" name="email" onChange={onChange}></Input>
        <Input type="password" placeholder="Senha" name="senha" onChange={onChange}></Input>

        <Button onClick={onSubmit}>ENTRAR</Button>
        <a href="/">Esqueceu sua senha?</a>
      </Card>
    </Wrapper>
  )
}

export default LoginRelatorio
