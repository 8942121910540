import styled from 'styled-components'
import colors from '../../Theme/colors'
import imagem from '../../Assets/Images/login.png'
import { styling, sizing } from '../../Theme/fonts'
import InputCnpjCpf from '../../Components/InputCnpjCpf'
import InputTelefone from '../../Components/InputTelefone'
import InputCep from '../../Components/InputCep'

export const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 107px);
  background-image: url(${imagem});
  background-size: cover;

  font-family: ${styling.NORMAL};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Card = styled.div`
  width: 50%;
  margin: 0 auto;
  background-color: ${colors.WHITE};
  border-radius: 4px;
  padding: 12px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    text-decoration: none;
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-size: ${sizing.SMALLER}px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: ${sizing.SMALLER}px;
    color: ${colors.COLOR_DEFAULT_BUTTON};

    text-align: center;
  }

  @media (max-width: 688px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 80%;
  }
`
export const Title = styled.h2`
  width: 100%;
  color: ${colors.COLOR_DEFAULT_BUTTON};
  text-transform: uppercase;
  font-size: 20px;
  margin: 10px 0;
  text-align: center;
`
export const MsgSuccess = styled.h2`
  width: Calc(100% - 40px);
  color: ${colors.GREEN};
  font-size: 22px;
  text-align: center;

  margin: 20px;
`

export const Input = styled.input`
  width: calc(100% -20px);
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: ${colors.BLACK};
  background-color: ${colors.VIOLET_BACKGROUND};
  margin-bottom: 10px;
`
export const CnpjCpf = styled(InputCnpjCpf)`
  width: calc(100% -20px);
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: ${colors.BLACK};
  background-color: ${colors.VIOLET_BACKGROUND};
  margin-bottom: 10px;
`
export const Telefone = styled(InputTelefone)`
  width: calc(100% -20px);
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: ${colors.BLACK};
  background-color: ${colors.VIOLET_BACKGROUND};
  margin-bottom: 10px;
`
export const Cep = styled(InputCep)`
  width: calc(100% -20px);
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: ${colors.BLACK};
  background-color: ${colors.VIOLET_BACKGROUND};
  margin-bottom: 10px;
`

export const Button = styled.button`
  border: none;
  border-radius: 15px;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  padding: 5px;
  color: ${colors.WHITE};
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALL}px;
  padding: 8px 30px;
  margin: 5px 9px 5px auto;
  cursor: pointer;
`

export const CardType = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`
export const BoxType = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 33%;
  cursor: pointer;
`
export const ImgType = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  img {
    width: 50px;
    height: 50px;
    top: 0px;
    right: 0px;
    background-size: cover;
    filter: grayscale(${(props) => (props.isSelected ? '0%' : '100%')});
  }
`
export const DescType = styled.div`
  padding: 4px;
  font-size: 14px;
  color: hsl(0, 0%, 20%);
`

export const Label = styled.label`
  margin: 0 0 8px;
  font-size: ${sizing.SMALL}px;
`
export const Form = styled.div`
  width: Calc(100% - 8px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 4px 0;
`
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 Calc(50% - 10px);
  width: 100%;
  padding: 5px;

  @media (max-width: 688px) {
    flex: 0 0 98%;
  }
`
