import React, { useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { Mapa } from './style'

const apiKey = 'AIzaSyB6sI-KG9Yj0PFnfqKkBw3Dqgfm7rMxzB4'
const additionalOptions = {}
const loader = new Loader({
  apiKey: apiKey,
  version: 'weekly',
  ...additionalOptions,
})

const MapaModal = ({ address }) => {
  useEffect(() => {
    let map
    loader.load().then((google) => {
      map = new google.maps.Map(document.getElementById('map'), {
        mapTypeId: google.maps.MapTypeId.TERRAIN,
        zoom: 18,
      })

      var geocoder = new google.maps.Geocoder()

      geocoder.geocode({ address: address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          new google.maps.Marker({
            position: results[0].geometry.location,
            map: map,
          })
          map.setCenter(results[0].geometry.location)
        }
      })
    })
  }, [address])

  return <Mapa id="map"></Mapa>
}

export default MapaModal
