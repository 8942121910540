import { React, useEffect, useState } from 'react'
import loading from './loading.gif'
import './style.css'

const Loader = () => {
  return (
    <div class="wrapper">
      <div class="overlay-wrapper">
        <div class="content">
          <div class="w-100">
          </div>
          <div class="w-100">
            <img src={loading} class="w-auto car" />
          </div>
        </div>
      </div>
    </div>
    )
}

export default Loader