import React, { Fragment, useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faShareSquare } from '@fortawesome/free-regular-svg-icons'
import { faGlobe, faMapMarkerAlt, faTimes, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faPinterest, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import colors from '../../Theme/colors'

import { GetAnuncios, AddFavorito, GetAssociadoById } from '../../Service/ApiService'
import { toast, Bounce } from 'react-toastify'
import { useSelector } from 'react-redux'

import {
    Info,
    CardContato,
    Logo,
    Share,
    Title,
    Row,
    ContatoItem,
    Descricao,
    Categorias,
    CardCategoria,
    Imagens,
    FrameFacebook,
    Wrapper,
    Container,
    CardHeader,
    Close,
  } from '../../Components/ModalHome/style'

import GalleryImages from '../../Components/GalleryImages'
import MapaModal from '../../Components/Mapa'
import useLoader from '../../Reducers/useLoader'

const SingleLoja = (props) => {
        
    const participanteId = props.match.params.id;
    const infos = {}
    const tokenType = useSelector((state) => state.TokenType)
    const tokenApp = useSelector((state) => state.TokenApp)
    const [favorite, setFavorite] = useState(false)
    const [anuncios, setAnuncios] = useState()
    const [values, setValues] = useState()
    const [share, setShare] = useState(false)
    const [loader, showLoader, hideLoader] = useLoader()

    useEffect(() => {
        showLoader()
        async function loadData() {
          if (participanteId){
              setValues(await GetAssociadoById(tokenApp, participanteId))
              hideLoader()
          } 
        }
    
        loadData()
      }, [participanteId])

    useEffect(() => {
        async function loadImagens() {
          showLoader()
          var result = await GetAnuncios(tokenApp, participanteId)
          if (result.length === 0) {
            setAnuncios(undefined)
            hideLoader()
          } else {
            setAnuncios(result)
            hideLoader()
          }
        }
        if(!anuncios) loadImagens()
      }, [anuncios])

    async function onFavorito(ev) {
        if (tokenType !== 'Cli') {
          window.location.href = '/Login?f=' + participanteId
        } else {
          favorito()
        }
      }
    
      async function favorito() {
        var response = await AddFavorito(tokenApp, participanteId)
        if (response.result) {
          setFavorite(!favorite)
        } else {
          toast(response.message, {
            type: 'warning',
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: 'top-right',
          })
        }
      }

    const justNumbers = (text='') => {
        var numbers = text.replace(/[^0-9]/g,'');
        return parseInt(numbers);
    }

    return (
    <Wrapper>
        {values && (
        <Container>
          <Row>
            <Info>
            <CardHeader>
                <Logo>
                <img src={values.urlLogo} alt={values.nome} />
                <Share>
                    <div>
                    <FontAwesomeIcon icon={faStar} color={favorite ? colors.YELLOW : ''} onClick={onFavorito} />
                    <FontAwesomeIcon icon={faShareSquare} onClick={() => setShare(!share)} />
                    </div>
                    {
                    {
                        true: (
                        <div className="btn">
                            <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FontAwesomeIcon width={64} icon={faFacebook} />
                            </a>
                            <a
                            href={`https://twitter.com/intent/tweet?text=${values.nome}&url=${window.location.href}&related=`}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FontAwesomeIcon width={64} icon={faTwitter} />
                            </a>
                            <a
                            href={`http://pinterest.com/pin/create/button/?url=${window.location.href}`}
                            target="_blank"
                            rel="noreferrer"
                            >
                            <FontAwesomeIcon width={64} icon={faPinterest} />
                            </a>
                        </div>
                        ),
                    }[share]
                    }
                </Share>
                </Logo>

                <CardContato>
                <Title>{values.nome}</Title>

                <ContatoItem>
                    {values.endereco !== ' - ' && (
                    <Fragment>
                        <FontAwesomeIcon width={64} icon={faMapMarkerAlt} />
                        <p>{values.enderecoComp}</p>
                    </Fragment>
                    )}
                </ContatoItem>
                <ContatoItem>
                    {values.urlSite !== '' && (
                    <Fragment>
                        <FontAwesomeIcon width={64} icon={faGlobe} />
                        <p>
                        <a href={`${values.urlSite}`} target="_blank" rel="noreferrer">
                            {values.urlSite}
                        </a>
                        </p>
                    </Fragment>
                    )}
                </ContatoItem>
                <ContatoItem>
                    {values.numWhatsApp !== '' && (
                    <Fragment>
                        <FontAwesomeIcon icon={faWhatsapp} />
                        <p>
                            <a href={`https://api.whatsapp.com/send?phone=55${justNumbers(values.numWhatsApp)}&text=Ol�, estava navegando no Guia da Constru��o e gostaria de mais informa��es`} target="_blank" rel="noreferrer">
                            {values.numWhatsApp}
                        </a>
                        </p>
                    </Fragment>
                    )}
                </ContatoItem>
                <ContatoItem>
                    {values.telefone !== '' && (
                    <Fragment>
                        <FontAwesomeIcon icon={faPhoneAlt} />
                        <p>{values.telefone}</p>
                    </Fragment>
                    )}
                </ContatoItem>
                </CardContato>
            </CardHeader>

            <Descricao>{values.descricao}</Descricao>
            </Info>
            {values.endereco !== undefined && values.endereco !== ' - ' && <MapaModal address={values.enderecoComp} />}
        </Row> 

         <Row>
            {values.categorias !== undefined && (
            <Categorias>
                {values.categorias.map((item, i) => (
                <CardCategoria key={i}>
                    <input id={`InCat_${i}`} value={item.nome} readOnly />
                    <label id={`LbCat_${i}`}>{item.nome}</label>
                </CardCategoria>
                ))}
            </Categorias>
            )}
        </Row> 

         <Row>
            {values.urlFacebook && (
            <FrameFacebook
                src={`https://www.facebook.com/plugins/page.php?href=${values.urlFacebook}&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                className="iframeFacebook"
                frameBorder="0"
                allowfullscreen="true"
                scrolling="false"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></FrameFacebook>
            )}

            {values.nome !== undefined && anuncios !== undefined && (
            <Imagens>
                <GalleryImages anuncios={anuncios} logoAnuncio={values.urlLogo}></GalleryImages>
            </Imagens>
            )} 
        </Row> 
        </Container>
         )}
         {loader}
    </Wrapper>
    );
}

export default SingleLoja;