import React, { Fragment, useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { GetCategorias, PostOrcamento, GetCep } from '../../Service/ApiService'
import ProgressBar from '../../Components/ProgressBar/index'
import { Bounce, toast } from 'react-toastify'

import {
  Container, Header, InputCEP, InputTelefone, TitleHeader,
  BoxProgress,
  Title,
  DetailsBox,
  Select,
  Label,
  Input,
  BtnArea,
  ButtonPrev,
  ButtonNext,
  TextArea,
  ListBox,
  List,
  ListItem,
  InputCheck,
  FormGroup,
} from './style'

const Orcamento = () => {
  const initValues = {
    descricao: '',
    categorias: [],
    finalide: '',
    metragem: '',
    ofertasEmail: '',
    tipoObra: '',
    nome: '',
    fone1: '',
    endereco: '',
    bairro: '',
    cidade: '',
    email: '',
    fone2: '',
    numero: '',
    cep: '',
    uf: '',
  }

  const tokenApp = useSelector((state) => state.TokenApp)
  const [categorias, setCategorias] = useState()
  const [values, setValues] = useState(initValues)
  const [etapa, setEtapa] = useState(1)

  const onChange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const onChangeProduct = (e) => {
    const { name, checked } = e.target
    let _products = []
    if (checked) {
      _products = values.categorias
      _products.push(name)
      setValues({ ...values, categorias: _products })
    } else {
      values.categorias.forEach((product) => {
        if (product !== name) _products.push(product)
      })
    }
    setValues({ ...values, categorias: _products })
  }

  useEffect(() => {
    async function loadCategorias() {
      setCategorias(await GetCategorias(tokenApp))
    }
    loadCategorias()
  }, [tokenApp])

  useEffect(() => {
    if (etapa === 4) {
      onSubmit()
    }
  }, [etapa])

  useEffect(() => {
    async function loadCep() {
      if (values.cep === undefined) return
      if (values.cep.length === 9) {
        var result = await GetCep(tokenApp, values.cep)
        if (result.msgResultado === 'ok') {
          setValues({
            ...values,
            endereco: result.logradouro,
            bairro: result.bairro,
            cidade: result.localidade,
            uf: result.uf,
          })
        } else {
          toast('CEP não encontrado', {
            type: 'error',
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: 'top-right',
          })
        }
      }
    }

    loadCep()
  }, [values.cep])

  function onClickNext() {
    if (etapa === 1){
      if (values.categorias.length === 0){
        toast('Selecione um produto ou serviço', {
          type: 'warning',
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: 'top-right',
        })
        return
      } else {
        setEtapa(2)
      } 
    } else if(etapa === 2){
      const verifyStep2 = values.finalide && values.metragem && values.tipoObra && values.ofertasEmail
      if(verifyStep2 === ''){
        toast('Preecha os campos obrigatórios', {
          type: 'warning',
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: 'top-right',
        })
      } else {
        setEtapa(3)
      }
    } else if(etapa === 3){
      const verifyStep3 = values.nome && values.email && values.fone && values.endereco && values.numero
      if (verifyStep3 === ''){
        toast('Preencha todos os campos obrigatórios', {
          type: 'warning',
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: 'top-right',
        })
      } else {
        setEtapa(4)
      }
    }
  }

  const onClickPrev = () => {
    if (etapa > 1) setEtapa(etapa - 1)
    if (etapa === 2) values.categorias = []
  }

  async function onSubmit() {
    var res = await PostOrcamento(tokenApp, values)
    if (res.result) {
      setTimeout(function () {
        window.location.href = '/'
      }, 3000)
    }
  }

  return (
    <Fragment>
      <Container>
        <Header>
          <TitleHeader>Orçamentos Grátis</TitleHeader>
          <p>Escolha o produto ou serviço que desejar cotar e receba os melhores preços das melhores empresas</p>
          <ProgressBar percentage={etapa} />
        </Header>
        {
          {
            1: (
              <BoxProgress>
                <Title>Passo 1 - Produtos ou Serviços Procurados</Title>
                <p>Para quais tipos de produtos e serviços você quer receber orçamentos?</p>

                <ListBox>
                  {categorias !== undefined ? (
                    <List>
                      {categorias.map((categoria, index) => (
                        <ListItem>
                          <InputCheck
                            id={index}
                            type="checkbox"
                            value={categoria.categoriaId}
                            name={categoria.nome}
                            onChange={onChangeProduct}
                          />
                          <label htmlFor={index}>{categoria.nome}</label>
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Fragment></Fragment>
                  )}
                </ListBox>

                <TextArea
                  name="descrição"
                  placeholder="Se preferir, dê mais detalhes sobre o que está procurando:"
                  onChange={onChange}
                  rows="6"
                />
                <BtnArea>
                  <ButtonNext onClick={onClickNext}>AVANÇAR</ButtonNext>
                </BtnArea>
              </BoxProgress>
            ),
            2: (
              <BoxProgress>
                <Title>Passo 2 - Detalhes da Obra</Title>
                <DetailsBox>
                  <FormGroup>
                    <Label>Finalidade *</Label>
                    <Select name="finalide" value={values.finalide} onChange={onChange} required>
                      <option value="">Selecione</option>
                      <option value="Construção">Construção</option>
                      <option value="Reforma">Reforma</option>
                      <option value="Decoração">Decoração</option>
                    </Select>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="metragem">Metragem aproximada (M²) *</Label>
                    <Input id="metragem" type="number" min="1" name="metragem" value={values.metragem} onChange={onChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Deseja receber ofertas e cupons por e-mail? *</Label>
                    <Select name="ofertasEmail" value={values.ofertasEmail} onChange={onChange}>
                      <option value="Selecione">Selecione</option>
                      <option value="Sim">Sim</option>
                      <option value="Nao">Não</option>
                    </Select>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="tipoDeObra">Tipo de obra *</Label>
                    <Select id="tipoObra" name="tipoObra" value={values.tipoObra} onChange={onChange}>
                      <option value="">Selecione</option>
                      <option value="Casa">Casa</option>
                      <option value="Casa em Condomínio">Casa em Condomínio</option>
                      <option value="Apartamento">Apartamento</option>
                      <option value="Comercial">Comercial</option>
                      <option value="Galpão">Galpão</option>
                      <option value="Empreendimento">Empreendimento</option>
                      <option value="Condomínio Horizontal">Condomínio Horizontal</option>
                      <option value="Condomínio Vertical">Condomínio Vertical</option>
                      <option value="Outros (Hotel, Igreja, Shopping)">Outros (Hotel, Igreja, Shopping)</option>
                    </Select>
                  </FormGroup>
                </DetailsBox>

                <BtnArea>
                  <ButtonPrev onClick={onClickPrev}>&#8630; VOLTAR</ButtonPrev>
                  <ButtonNext onClick={onClickNext}>AVANÇAR</ButtonNext>
                </BtnArea>
              </BoxProgress>
            ),
            3: (
              <BoxProgress>
                <Title>Passo 3 - Dados para Contato</Title>
                <DetailsBox>
                  <FormGroup>
                    <Label htmlFor="nome">NOME *</Label>
                    <Input id="nome" type="text" name="nome" value={values.nome} onChange={onChange}></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">EMAIL *</Label>
                    <Input 
                      id="email" type="email" name="email" 
                      value={values.email} onChange={onChange}></Input>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="fone1">FONE 1 *</Label>
                    <InputTelefone id="fone1" type="tel" name="fone1" value={values.fone1} onChange={onChange}/>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="fone2">FONE 2</Label>
                    <InputTelefone id="fone2" type="tel" name="fone2" value={values.fone2} onChange={onChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="cep">CEP *</Label>
                    <InputCEP id="cep" type="cep" name="cep" value={values.cep} onChange={onChange}/>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlfor="address">ENDEREÇO</Label>
                    <Input
                      id="address"
                      type="address"
                      name="endereco"
                      value={values.endereco}
                      onChange={onChange}
                    ></Input>{' '}
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="numero">NÚMERO</Label>
                    <Input id="numero" type="number" min="1" name="numero" value={values.numero} onChange={onChange}></Input>
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="bairro">BAIRRO</Label>
                    <Input id="bairro" type="text" name="bairro" value={values.bairro} onChange={onChange}></Input>
                  </FormGroup>

                  <FormGroup>
                    <Label htmlFor="cidade">CIDADE</Label>
                    <Input id="cidade" type="text" name="cidade" value={values.cidade} onChange={onChange} disabled />
                  </FormGroup>
                  <FormGroup>
                    <Label>ESTADO</Label>
                    <Input name="uf" value={values.uf} onChange={onChange} disabled />
                  </FormGroup>
                </DetailsBox>
                <BtnArea>
                  <ButtonPrev onClick={onClickPrev}>&#8630; VOLTAR</ButtonPrev>
                  <ButtonNext onClick={onClickNext}>AVANÇAR</ButtonNext>
                </BtnArea>
              </BoxProgress>
            ),
            4: (
              <BoxProgress>
                <Title style={{ textAlign: 'center' }}>Dados Enviados!</Title>
              </BoxProgress>
            ),
          }[etapa]
        }
      </Container>
    </Fragment>
  )
}

export default Orcamento
