import React, { useEffect } from 'react'

import { BrowserRouter, Switch } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

import AppHeader from './Layout/AppHeader'

import AppMain from './Layout/AppMain'
import AppFooter from './Layout/AppFooter'

import { GetTokenApp } from './Service/ApiService'
import { useSelector, useDispatch } from 'react-redux'
import { Fragment } from 'react'
function App() {
  const dispatch = useDispatch()
  const tokenApp = useSelector((state) => state.TokenApp)
  const tokenExpiration = useSelector((state) => state.TokenExpiration)

  useEffect(() => {
    async function getToken() {
      const response = await GetTokenApp()
      if (response.result) {
        dispatch({ type: 'TokenExpiration', value: response.expiration })
        dispatch({ type: 'TokenApp', value: response.token })
        dispatch({ type: 'TokenType', value: 'App' })
      }
    }

    if (!tokenApp || !tokenExpiration || new Date(tokenExpiration) < new Date()) {
      getToken()
    }
  }, [])

  return (
    <BrowserRouter>
      <PerfectScrollbar>
        <AppHeader />
        <div>
          <Switch>{tokenApp !== undefined ? <AppMain /> : <Fragment />}</Switch>
        </div>
        <AppFooter />
      </PerfectScrollbar>
    </BrowserRouter>
  )
}

export default App
