import { React } from 'react'
import { Wrapper } from './style'

import { Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import Home from '../../Pages/Home'
import Login from '../../Pages/Login'
import Contato from '../../Pages/Contato'

import Orcamento from '../../Pages/Orcamentos'
import ListLojas from '../../Pages/ListLojas'

import QuemSomos from '../../Pages/QuemSomos'
import ComoTrabalhamos from '../../Pages/ComoTrabalhamos'
import Privacidade from '../../Pages/Privacidade'

import Artigo from '../../Pages/Artigos/Detalhes'
import Artigos from '../../Pages/Artigos/Lista'

import CupomDetalhes from '../../Pages/Cupons/Detalhes'
import Cadastro from '../../Pages/Cadastro'
import SingleLoja from '../../Pages/SingleLoja'

const Main = () => {
  return (
    <Wrapper>
      <Route path="/" exact render={(props) => <Home {...props} />} />
      <Route path="/home" render={(props) => <Home {...props} />} />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/contato" render={(props) => <Contato {...props} />} />
      <Route path="/orcamentos" render={(props) => <Orcamento {...props} />} />
      <Route path="/associados" render={(props) => <ListLojas {...props} />} />
      <Route path="/quemsomos" render={(props) => <QuemSomos {...props} />} />
      <Route path="/comotrabalhamos" render={(props) => <ComoTrabalhamos {...props} />} />
      <Route path="/politica-de-privacidade" render={(props) => <Privacidade {...props} />} />
      <Route path="/artigo/:titulo" render={(props) => <Artigo {...props} />} />
      <Route path="/artigos" render={(props) => <Artigos {...props} />} />
      <Route path="/MeuCupom/:id" render={(props) => <CupomDetalhes {...props} />} />
      <Route path="/Cadastro" render={(props) => <Cadastro {...props} />} />
      <Route path="/associado/:id/:nome" render={(props) => <SingleLoja {...props} />} />

      <div>
        <a href="https://wa.me/5519992050538" className="btn-Whatsapp" target="_blank" rel="noreferrer">
          <FontAwesomeIcon width={64} icon={faWhatsapp} />
        </a>
      </div>
      <ToastContainer />
    </Wrapper>
  )
}

export default Main
