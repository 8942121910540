import styled from 'styled-components'
import colors from '../../../Theme/colors'
import { styling } from '../../../Theme/fonts'

export const Wrapper = styled.div`
  width: 100% -10px;
  margin-top: 100px;

`
export const Title = styled.h2`
  margin: 0 auto;
  padding: 15px 0;
  color: #000;
  padding: 20px 20px;
  font-family: ${styling.NORMAL};
  font-size: 28px;
  font-weight: normal;
  color: ${colors.BLACK};
`
export const Container = styled.div`
  width: 100%;
  padding: 5px;
  color: #fff;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 688px) {
    flex-direction: column;
    padding: 0;
  }
`
export const Card = styled.div`
  width: 45%;
  background: #fff;
  color: #fff;

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0px 5px;

  @media (max-width: 688px) {
    width: calc(100% - 20px);
    padding: 5px 10px 10px;
  }
`

export const FrameYoutube = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
  border: none;
`
