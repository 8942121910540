import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper, Categoria, CardImg, SliderBar } from './style'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GetCategorias } from '../../Service/ApiService'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

const CategoriaCarrossel = () => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const history = useHistory()
  const dispatch = useDispatch()
  const [categorias, setCategorias] = useState([])

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,

    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    async function loadData() {
      setCategorias(await GetCategorias(tokenApp))
    }
    loadData()
  }, [tokenApp])

  function onSearch(e) {
    dispatch({ type: 'Filtro_Busca', value: { produto: e.categoriaId, nmCategoria: e.nome } })
    history.push('/associados')
  }

  return (
    <Wrapper>
      <SliderBar {...settings}>
        {categorias.map(
          (d) =>
            d.img && (
              <Fragment>
                <div key={d.categoriaId}>
                  <Categoria data-tip={d.nome} data-place="top"
                    onClick={() => {
                      onSearch(d)
                    }}
                  >
                    <CardImg>
                      <img src={d.img} alt="imagem" />
                    </CardImg>
                  </Categoria>
                  <ReactTooltip />
                </div>
              </Fragment>
            )
        )}
      </SliderBar>
    </Wrapper>
  )
}

export default CategoriaCarrossel
