import React, { Fragment, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { GetMeuCupom } from '../../../Service/ApiService'

import FormSearch from '../../../Components/FormPesquisa'
import {
  Wrapper,
  Container,
  CardCupom,
  Details,
  Title,
  BoxNrCupom,
  ButtonCopyNrCupom,
  LabelCopyNrCupom,
  LabelNrCupom,
  Validade,
  Regulamento,
} from './styles'
import QrCode from 'react-qrcode-svg'

const ListLojas = (props) => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const [cupom, setCupom] = useState()
  useEffect(() => {
    async function loadData() {
      var result = await GetMeuCupom(tokenApp, props.match.params.id)
      if (result.dhPrazoUtilizacao != null) {
        result.dhPrazoUtilizacao = new Date(result.dhPrazoUtilizacao)
          .toISOString()
          .substr(0, 10)
          .split('-')
          .reverse()
          .join('/')
      } else {
        result.dhPrazoUtilizacao = ''
      }

      setCupom(result)
    }

    loadData()
  }, [props.match.params.id, tokenApp])

  const onCopyNrCupom = async () => {
    //Clipboard.setString(cupom.nrCupom)
  }

  return (
    <Fragment>
      <FormSearch></FormSearch>
      <Wrapper>
        <Container>
          {cupom !== undefined ? (
            <Fragment>
              <CardCupom>
                <QrCode height="300" width="300" data={cupom.nrCupom} />
                <Details>
                  <Title numberOfLines={1}>{cupom.titulo}</Title>

                  <BoxNrCupom>
                    <LabelNrCupom>{cupom.nrCupom}</LabelNrCupom>
                    <ButtonCopyNrCupom onPress={onCopyNrCupom}>
                      <LabelCopyNrCupom>COPIAR</LabelCopyNrCupom>
                    </ButtonCopyNrCupom>
                  </BoxNrCupom>

                  {cupom.dhPrazoUtilizacao != null && <Validade>Valido até {cupom.dhPrazoUtilizacao}</Validade>}

                  <Regulamento>{cupom.regulamento}</Regulamento>
                </Details>
              </CardCupom>
            </Fragment>
          ) : (
            <Fragment />
          )}
        </Container>
      </Wrapper>
    </Fragment>
  )
}

export default ListLojas
