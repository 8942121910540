import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling } from '../../Theme/fonts'

export const Wrapper = styled.div``
export const Container = styled.div`
  width: 95%;
  margin: 10px auto;

  display: flex;
  flex-wrap: wrap;
  font-family: ${styling.NORMAL};
`
export const Title = styled.h1`
  color: ${colors.COLOR_DEFAULT_BUTTON};
  font-weight: 400;
  font-size: 28px;

  margin: 15px 0 5px 0;
`
export const Content = styled.div``
