import { React, useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './carousel.css'
import { Carousel } from 'react-responsive-carousel'
import { Slide } from './style'
import { useSelector } from 'react-redux'
import { GetAnunciosForBanner } from '../../Service/ApiService'
import { Fragment } from 'react'
const BannerCarrossel = () => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const [values, setValues] = useState([])

  useEffect(() => {
    async function loadData() {
      setValues(await GetAnunciosForBanner(tokenApp))
    }
    loadData()
  }, [tokenApp])

  return (
    <Fragment>
      {values !== undefined ? (
        <Carousel
          showStatus={false}
          className="carousel-slider"
          autoPlay={true}
          interval={2000}
          infiniteLoop={true}
          showThumbs={false}
          showIndicators={false}
        >
          {values?.map((d) => (
            <Slide key={d.anuncioId}>
              <img src={d.urlImage} alt={d.descricao ? d.descricao : 'imagem'} />
            </Slide>
          ))}
        </Carousel>
      ) : (
        <Fragment />
      )}
    </Fragment>
  )
}
export default BannerCarrossel
