import styled from 'styled-components'
import colors from '../../Theme/colors'
import { sizing, styling } from '../../Theme/fonts'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${styling.NORMAL};
`
export const Container = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`
export const CardList = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 25px;
`

export const CardBoxInline = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 16px;

  /* @media(max-width: 688px){
    display: none;
  } */
`

export const ItensSearch = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 688px) {
    justify-content: flex-end;
  }
`
export const Itens = styled.div`
  width: calc(100% - 190px);
  display: flex;
  flex-direction: row;

  @media (max-width: 688px) {
    display: none;
  }
`
export const Item = styled.div`
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  border-radius: 20px;
  color: ${colors.WHITE};
  padding: 6px 8px 6px 12px;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 5px;

  > * {
    margin: 0 4px;
  }
`
export const Label = styled.label`
  font-size: ${sizing.SMALL}px;
  font-weight: bold;
`
export const Button = styled.div`
  border: none;
  background: none;
  color: ${colors.WHITE};
  font-weight: bold;
  cursor: pointer;
`
export const ViewList = styled.div`
  width: 190px;
  display: flex;
  justify-content: center;
  > * {
    margin: 0 5px;
  }

  @media(max-width: 688px){
    display: none;
  }
`
export const IconSpinner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
