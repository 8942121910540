import styled from 'styled-components'
import colors from '../../../Theme/colors'
import { styling } from '../../../Theme/fonts'

export const CardWrapper = styled.div`
  width: 30%;
  min-width: 150px;
  min-height: 300px;
  font-family: ${styling.NORMAL};

  display: flex;
  flex-direction: column;

  background-color: ${colors.WHITE};
  border-radius: 5px;
  padding: 12px;
  &:hover {
    img {
      transform: rotate(5deg) scale(1.1);
    }
  }

  @media (max-width: 992px) {
    width: Calc(50% - 45px);
    margin: 10px 10px;
  }

  @media (max-width: 688px) {
    width: 100%;
    margin: 10px 20px;
  }
`

export const CardHeader = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
`

export const Img = styled.img`
  width: 100%;
  aspect-ratio: 16 / 8;
  background-color: grey;
  transition: transform 0.5s ease;
`

export const CardBody = styled.div`
  width: 100%;
`

export const Title = styled.h3`
  width: 100%;
  height: 43px;
  font-family: ${styling.NORMAL};
  font-weight: bold;
  color: ${colors.BLACK};
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform 1s ease;
`

export const SubTitle = styled.div`
  width: 100%;
  color: ${colors.BLACK};
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
`

export const CardFooter = styled.div`
  width: 100%;
  margin-top: 15px;
  a {
    color: ${colors.COLOR_DEFAULT_BUTTON};
    text-decoration: none;
    font-weight: 700;
    font-family: ${styling.NORMAL};
    transition: color 1s ease;
    &:hover {
      color: ${colors.GREEN};
    }
  }
`
