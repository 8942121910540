import { React } from 'react'
import { CardWrapper, CardHeader, Img, CardBody, Title, SubTitle, CardFooter } from './style'

const CardArtigo = ({ artigo }) => {
  const { artigoId, titulo, resumo, urlImage } = artigo
  //console.log('artigo', artigo)

  const urlFormatter = (urlParamer) =>
    urlParamer
      .replaceAll(' ', '+')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')

  //console.log('url', urlFormatter(artigo.titulo))
  return (
    <CardWrapper id={artigoId}>
      <CardHeader href={`/artigo/${artigo.url}`}>
        <Img src={urlImage}></Img>
      </CardHeader>
      <CardBody>
        <Title>{titulo}</Title>
        <SubTitle>{resumo}</SubTitle>
      </CardBody>
      <CardFooter>
        <a href={`/artigo/${artigo.url}`}>Leia o artigo completo</a>
      </CardFooter>
    </CardWrapper>
  )
}
export default CardArtigo
