import { React, useEffect, useState } from 'react'
import {
  CardWrapper,
  FrontCard,
  BackCard,
  CardHeader,
  Img,
  Desc,
  CardBody,
  Title,
  CardFooter,
  Info,
  InfoIcon,
} from './style'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faGlobe, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { GetAssociadoById } from '../../../Service/ApiService'

const CardEmpresa = ({ participanteId, onClick }) => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const [values, setValues] = useState()

  useEffect(() => {
    async function loadData() {
      setValues(await GetAssociadoById(tokenApp, participanteId))
    }

    loadData()
  }, [tokenApp, participanteId])

  const justNumbers = (text) => {
    var numbers = '55' + text.replace(/[^0-9]/g,'');
    return parseInt(numbers);
  }
  
  return (
    <CardWrapper>
      {values && (
        <Fragment>
          <FrontCard>
            <CardHeader>
              <Img src={values.urlLogo}></Img>
              <Desc>{values.descricao}</Desc>
            </CardHeader>
            <CardBody>
              <Title>{values.nome}</Title>
            </CardBody>
            <CardFooter>
              <Info>
                {values.endereco !== ' - ' && (
                  <Fragment>
                    <InfoIcon className="icon">
                      <FontAwesomeIcon width={64} icon={faMapMarkerAlt} />
                    </InfoIcon>
                    <p>{values.endereco}</p>
                  </Fragment>
                )}
              </Info>
              <Info>
                {values.urlSite && (
                  <Fragment>
                    <InfoIcon className="icon">
                      <FontAwesomeIcon width={64} icon={faGlobe} />
                    </InfoIcon>
                    <p>
                      <a href={`${values.urlSite}`} target="_blank" rel="noreferrer">
                        {values.urlSite}
                      </a>
                    </p>
                  </Fragment>
                )}
              </Info>
              <Info>
                {values.numWhatsApp !== '' && (
                  <Fragment>
                    <InfoIcon className="icon">
                      <FontAwesomeIcon width={64} icon={faWhatsapp} />
                    </InfoIcon>
                    <p>
                      <a href={`https://api.whatsapp.com/send?phone=${justNumbers(values.numWhatsApp)}&text=Olá, estava navegando no Guia da Construção e gostaria de mais informações`} target="_blank" rel="noreferrer">
                        {values.numWhatsApp}
                      </a>
                    </p>
                  </Fragment>
                )}
              </Info>
            </CardFooter>
          </FrontCard>
          <BackCard>
            <CardHeader onClick={(e) => onClick(values)}>
              <Img src={values.urlLogo}></Img>
              <Desc>{values.descricao}</Desc>
            </CardHeader>
            <CardBody onClick={(e) => onClick(values)}>
              <Title>{values.nome}</Title>
            </CardBody>
            <CardFooter>
              <Info>
                {values.endereco !== ' - ' && (
                  <Fragment>
                    <InfoIcon className="icon">
                      <FontAwesomeIcon width={64} icon={faMapMarkerAlt} />
                    </InfoIcon>
                    <p>{values.endereco}</p>
                  </Fragment>
                )}
              </Info>
              <Info>
                {values.urlSite && (
                  <Fragment>
                    <InfoIcon className="icon">
                      <FontAwesomeIcon width={64} icon={faGlobe} />
                    </InfoIcon>
                    <p>
                      <a href={`${values.urlSite}`} target="_blank" rel="noreferrer">
                        {values.urlSite}
                      </a>
                    </p>
                  </Fragment>
                )}
              </Info>
              <Info>
                {values.numWhatsApp !== '' && (
                  <Fragment>
                    <InfoIcon className="icon">
                      <FontAwesomeIcon width={64} icon={faWhatsapp} />
                    </InfoIcon>
                    <p>
                      <a href={`https://wa.me/${values.numWhatsApp}`} target="_blank" rel="noreferrer">
                        {values.numWhatsApp}
                      </a>
                    </p>
                  </Fragment>
                )}
              </Info>
            </CardFooter>
          </BackCard>
        </Fragment>
      )}
    </CardWrapper>
  )
}
export default CardEmpresa
