import React, { useState } from 'react'
import { ContatoWrapper, FormWrapper, Contatos, Form, Title, Input, Telefone, TextArea, Button } from './style'

import { PostContato } from '../../Service/ApiService'
import { useSelector } from 'react-redux'
import { toast, Bounce } from 'react-toastify'

const Contato = () => {
  const [values, setValues] = useState({ nome: '', email: '', telefone: '', empresa: '', mensagem: '' })
  const tokenApp = useSelector((state) => state.TokenApp)

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onEnviar(ev) {
    ev.preventDefault()

    if (!values.nome || !values.email || !values.telefone || !values.empresa || !values.mensagem) {
      toast('Preencha todos os campos', {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      return
    }

    var res = await PostContato(tokenApp, values)
    toast(res.message, {
      type: 'success',
      transition: Bounce,
      closeButton: true,
      autoClose: 3000,
      position: 'top-right',
    })
    if (res.result) {
      setTimeout(function () {
        window.location.href = '/'
      }, 3000)
    }
  }

  return (
    <ContatoWrapper>
      <FormWrapper>
        <Title>Fale com o Guia da Construção</Title>
        <Contatos>
          <div>(19) 99205 0638</div>
          <div> contato@guiadaconstrucao1.com.br</div>
        </Contatos>
        <Form>
          <Input
            type="text"
            name="nome"
            placeholder="Nome"
            maxLength="64"
            value={values.nome}
            onChange={onChange}
          ></Input>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            maxLength="64"
            value={values.email}
            onChange={onChange}
          ></Input>
          <Telefone
            type="tel"
            name="telefone"
            placeholder="Telefone"
            value={values.telefone}
            onChange={onChange}
          ></Telefone>
          <Input
            type="text"
            name="empresa"
            placeholder="Empresa"
            maxLength="64"
            value={values.empresa}
            onChange={onChange}
          ></Input>
          <TextArea
            type="textarea"
            name="mensagem"
            rows="6"
            maxLength="256"
            placeholder="Mensagem"
            value={values.mensagem}
            onChange={onChange}
          ></TextArea>

          <Button onClick={onEnviar}>ENVIAR</Button>
        </Form>
      </FormWrapper>
    </ContatoWrapper>
  )
}

export default Contato
