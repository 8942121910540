import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts'
import imagem from '../../Assets/Images/loginrelatoriodeobras.jpg'

export const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 107px);
  background-image: url(${imagem});
  background-size: cover;

  font-family: ${styling.NORMAL};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const Card = styled.div`
  width: 30%;
  margin: 0 120px;
  background-color: ${colors.WHITE};
  border-radius: 4px;
  padding: 12px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  a {
    text-decoration: none;
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-size: ${sizing.SMALLER}px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: ${sizing.SMALLER}px;
    color: ${colors.COLOR_DEFAULT_BUTTON};

    text-align: center;
  }

  @media (max-width: 688px) {
    width: 100%;
    margin: 0 12px;
  }
`
export const Title = styled.h2`
  width: 100%;
  color: ${colors.COLOR_DEFAULT_BUTTON};
  text-transform: uppercase;
  font-size: 22px;
  margin: 10px 0;
  text-align: center;
`
export const Form = styled.form`
  padding: 5px;
  width: 90%;
  gap: 7px;
`
export const Input = styled.input`
  width: calc(100% -20px);
  border: none;
  border-radius: 15px;
  padding: 10px;
  color: ${colors.BLACK};
  background-color: ${colors.VIOLET_BACKGROUND};
  margin-bottom: 10px;
`
export const Button = styled.button`
  width: 100%;
  border: none;
  border-radius: 15px;
  background-color: ${colors.COLOR_DEFAULT_BUTTON};
  padding: 5px;
  color: ${colors.WHITE};
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALL}px;
  padding: 8px;
  margin-bottom: 10px;
  cursor: pointer;
`
