import styled from "styled-components";
import colors from "../../Theme/colors";
import { sizing, styling } from '../../Theme/fonts';
import imagem from '../../Assets/Images/quemsomos.jpg';

export const ContainerWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: ${styling.NORMAL};

`;
export const ContainerInfo = styled.div`
    width: 90%;
`;
export const Titulo = styled.h1`
    color: ${colors.COLOR_DEFAULT_BUTTON};
    font-size: 28px;
    font-weight: normal;
    margin: 15px 0 5px 0;
`;
export const Textos = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: ${sizing.SMALL}px;
    line-height: 27px;

    @media(max-width: 688px) {
        flex-direction: column;
    }
    
`;
export const Texto = styled.p`
    width: 45%;
    margin: 0;
    
    @media(max-width: 688px) {
        width: 100%;
        :first-child{
            border-bottom: 4px solid rgba(0,0,0, 0.2);
        }
    }
`;
export const Anuncio = styled.div`
    background-color: rgba(0,0,0, 0.2);
    width: 100%;
    margin-top: 10px;

    h2{
        font-size: 28px;
        font-weight: normal;
        width: 60%;
        
        @media(max-width: 688px) {
            margin: 15px 0;
            width: 100%;
            text-align: center;
        }
    }
`;
export const Contato = styled.div`
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    
    @media(max-width: 688px) {
        padding: 0 0 10px 0;
        flex-direction: column;
    }
`;
export const Button = styled.button`
    color: ${colors.WHITE};
    background-color: ${colors.COLOR_DEFAULT_BUTTON};
    width: 25%;
    padding: 8px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-family: ${styling.NORMAL};
    font-size: ${sizing.LARGE}px;
    letter-spacing: 1px;

    @media(max-width: 688px) {
        width: 90%;
    }
`;

export const Atuacao = styled.div`
    width: 90%;

`;
export const BoxInfo = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
export const List = styled.div`
    width: 60%;
    margin-bottom: 30px;

    li {
        font-size: ${sizing.SMALL}px;
        font-family: ${styling.NORMAL};
        line-height: 5vh;
        list-style: none;
    }
    
    @media(max-width: 688px) {
        width: 100%;
    }
`;
export const Img = styled.div`
    width: 40%;
    background-image: url(${imagem});
    background-size: contain;
    background-repeat: no-repeat;
    
    @media(max-width: 688px) {
        display: none;
    }
`;
export const Depoimento = styled.div`
    width: 100%;
    background-color: rgba(0,0,0, 0.06);
    padding: 25px;
    display: flex;
    justify-content: center;
`;
export const FrameYoutube = styled.iframe`
    width: 100%;
    max-width: 560px;
    aspect-ratio: 16 / 9;
    border: none;
`;
