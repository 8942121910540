import { React, useEffect, useState } from 'react'
import { Wrapper, Container, Title, CardBox, BtnMais } from './styles'

import CardArtigo from '../Card'
import { GetArtigos } from '../../../Service/ApiService'
import { useSelector } from 'react-redux'

const Header = ({ Titulo }) => {
  const tokenApp = useSelector((state) => state.TokenApp)
  const [artigos, setArtigos] = useState()

  useEffect(() => {
    async function loadData() {
      setArtigos(await GetArtigos(tokenApp))
    }

    loadData()
  }, [tokenApp])

  return (
    <Wrapper>
      <Container>
        <Title>{Titulo}</Title>
        <CardBox>
          {artigos?.slice(0, 3).map((artigo, index) => (
            <CardArtigo key={index} artigo={artigo} />
          ))}
        </CardBox>
        <BtnMais href="/artigos"> Mais Artigos</BtnMais>
      </Container>
    </Wrapper>
  )
}

export default Header
