import styled from 'styled-components'
//import colors from "../../Theme/colors";

export const Slide = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 50%;
    object-fit: cover;
  }
`
