import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, Bounce } from 'react-toastify'
import {
  Wrapper,
  Card,
  Title,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardType,
  BoxType,
  ImgType,
  DescType,
  CnpjCpf,
  Telefone,
  Cep,
  MsgSuccess,
} from './style'
import { GetCep, ParticipanteCadastrar } from '../../Service/ApiService'
import typeCli from '../../Assets/Images/type-cliente.jpg'
import typeFor from '../../Assets/Images/type-fornecedor.jpg'
import typePro from '../../Assets/Images/type-prof.jpg'

const Cadastro = () => {
  const [values, setValues] = useState({ tipo: '', tpAtuacao: '', email: '', situacao: 'A' })
  const tokenApp = useSelector((state) => state.TokenApp)
  const [etapa, setEtapa] = useState(0)

  function onChange(ev) {
    const { name, value } = ev.target
    setValues({ ...values, [name]: value })
  }

  async function onSubmit(ev) {
    ev.preventDefault()
  }

  useEffect(() => {
    async function loadCep() {
      if (values.endCep === undefined) return
      if (values.endCep.length === 9) {
        var result = await GetCep(tokenApp, values.endCep)
        if (result.msgResultado === 'ok') {
          setValues({
            ...values,
            endLogradouro: result.logradouro,
            endBairro: result.bairro,
            endCidade: result.localidade,
            endEstado: result.uf,
          })
        } else {
          toast('CEP não encontrado', {
            type: 'error',
            transition: Bounce,
            closeButton: true,
            autoClose: 5000,
            position: 'top-right',
          })
        }
      }
    }

    loadCep()
  }, [values.endCep])

  async function onSubmit(ev) {
    ev.preventDefault()
    if (!values.tipo) {
      toast('Selecione o tipo da conta', {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      return
    }

    if (
      !values.nome ||
      !values.cpfCnpj ||
      !values.email ||
      !values.situacao ||
      !values.endCep ||
      !values.endCidade ||
      !values.endComp ||
      !values.endNumero ||
      !values.telefone
    ) {
      toast('Informe os campos obrigatórios', {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      return
    }
    var response = await ParticipanteCadastrar(tokenApp, values)

    if (response.result) {
      setEtapa(1)
    } else {
      toast(response.mensagem, {
        type: 'warning',
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: 'top-right',
      })
      return
    }
  }

  return (
    <Wrapper>
      {etapa === 0 ? (
        <Card>
          <Title>Informe seus dados para se cadastrar</Title>
          <CardType>
            <BoxType
              onClick={(e) => {
                setValues({ ...values, tipo: 'C', tpAtuacao: '' })
              }}
            >
              <ImgType isSelected={values.tipo === 'C'}>
                <img src={typeCli} alt="type" />
              </ImgType>
              <DescType>Cliente</DescType>
            </BoxType>
            <BoxType
              onClick={(e) => {
                setValues({ ...values, tipo: 'P' , tpAtuacao: 'L' })
              }}
            >
              <ImgType isSelected={values.tipo === 'P' && values.tpAtuacao === 'L'}>
                <img src={typePro} alt="type" />
              </ImgType>
              <DescType>Fornecedor</DescType>
            </BoxType>
            <BoxType
              onClick={(e) => {
                setValues({ ...values, tipo: 'P', tpAtuacao: 'P' })
              }}
            >
              <ImgType isSelected={values.tipo === 'P' && values.tpAtuacao === 'P'}>
                <img src={typeFor} alt="type" />
              </ImgType>
              <DescType>Profissional</DescType>
            </BoxType>
          </CardType>

          <Form>
            <FormGroup>
              <Label>Nome *</Label>
              <Input id="nome" type="text" name="nome" value={values.nome} onChange={onChange} />
            </FormGroup>
            <FormGroup>
              <Label>CPF/CNPJ *</Label>
              <CnpjCpf id="cpfCnpj" name="cpfCnpj" value={values.cpfCnpj} onChange={onChange} />
            </FormGroup>
            <FormGroup>
              <Label>E-mail *</Label>
              <Input id="email" type="email" name="email" value={values.email} onChange={onChange} />
            </FormGroup>
            <FormGroup>
              <Label>Telefone *</Label>
              <Telefone id="telefone" name="telefone" value={values.telefone} onChange={onChange} />
            </FormGroup>

            <FormGroup>
              <Label>CEP *</Label>
              <Cep id="endCep" name="endCep" value={values.endCep} onChange={onChange} />
            </FormGroup>
            <FormGroup>
              <Label>Endereço *</Label>
              <Input
                id="endLogradouro"
                name="endLogradouro"
                value={values.endLogradouro !== undefined ? `${values.endLogradouro}, ${values.endBairro} - ${values.endCidade}` : ''}
                onChange={onChange}
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Label>Nr *</Label>
              <Input id="endNumero" name="endNumero" value={values.endNumero} onChange={onChange} />
            </FormGroup>
            <FormGroup>
              <Label>Complemento *</Label>
              <Input id="endComp" name="endComp" value={values.endComp} onChange={onChange} />
            </FormGroup>
          </Form>

          <Button onClick={onSubmit}>Salvar</Button>
        </Card>
      ) : (
        <Card>
          <MsgSuccess>
            Seus dados foram salvos com sucesso, verifique seu e-mail e as instruções para ativação da conta
          </MsgSuccess>
        </Card>
      )}
    </Wrapper>
  )
}

export default Cadastro
