import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1050px;
  font-family: ${styling.NORMAL};
  //padding: 15px;
`
export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 0;
  background: ${colors.GRAY};
  color: #fff;

  border-top: 6px solid ${colors.YELLOW_GUIA};
`

export const InfoTitle = styled.h2`
  width: Calc(100% - 15px);
  margin: 0 0 0 15px;
  font-size: ${sizing.MEDIUM}px;
`
export const CardInfos = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 20px;

  @media (max-width: 688px) {
    flex-direction: column;
    padding: 2px 20px;
  }
`
export const CardInfo = styled.div`
  width: 33%;
  margin: 5px;

  @media (min-width: 688px) {
    :not(:last-child) {
      border-right: 1px solid ${colors.WHITE};
    }
  }

  @media (max-width: 688px) {
    width: 100%;
    padding: 5px 0px;
    :not(:first-child) {
      border-top: 2px solid ${colors.WHITE};
    }
  }
`
export const MenuInfo = styled.div`
  display: flex;
  flex-direction: column;
`
export const ItemMenu = styled.a`
  padding: 4px;
  color: ${colors.WHITE};
  text-decoration: none;
  font-size: ${sizing.SMALL}px;

  svg {
    margin-right: 10px;
  }
  :hover {
    font-weight: 600;
  }
`
export const CardCopy = styled.div`
  margin: 10px auto 0;
  padding: 10px 40px 10px;
  background-color: rgba(250, 251, 252, 0.3);
  font-size: ${sizing.SMALL}px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 688px) {
    padding: 10px 10px 10px;
    justify-content: center;
    span:nth-child(1n) {
      display: none;
    }
  }
`

export const ContainerLogo = styled.div`
  width: Calc(100% - 30px);

  padding: 0 0 20px 10px;
  img {
    width: 120px;
    //height: 40px;
  }

  @media (max-width: 688px) {
    padding: 0 0 10px 10px;
  }
`
