const colors = {
    RED: '#DA222B',
    YELLOW: '#FFB800',
    BLUE: '#0EA2D0',
    GREEN: '#26C740',
    WHITE: '#FFFFFF',
    GRAY: '#929292',
    BLACK: '#000',
    BLUE_1: '#00C9DB',
    MEDIUM_BLUE: '#4050FA',
    LIGHT_BLUE: '#9AC5EE',
    COLOR_DEFAULT_APP: '#d67c1b',
    COLOR_DEFAULT_BUTTON: '#F79318',
    VIOLET_BACKGROUND: '#F2E6EA',

    SECONDARY: '#ebedef',    
    YELLOW_GUIA: '#F7931E'

  };

export default colors
  