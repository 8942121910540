import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

import { Wrapper, Container, Title, CardBox } from './styles'

import CardArtigo from '../Card'
import { GetArtigos } from '../../../Service/ApiService';
import BannerCarrossel from '../../../Components/BannerCarrossel'

const Header = () => {
    const tokenApp = useSelector(state => state.TokenApp);
    const [artigos, setArtigos] = useState()

    useEffect(() => {
        async function loadData() {
            setArtigos(await GetArtigos(tokenApp));
        }

        loadData();
    }, [tokenApp])
    
    return (
        <Wrapper>
            <BannerCarrossel />
            <Container>
                    <Title>Dicas para sua <span>Obra</span></Title>
                    <CardBox>
                        {artigos?.map((artigo, index) => (
                            <CardArtigo key={index} artigo={artigo} />
                        ))}
                    </CardBox>
                </Container>
        </Wrapper>
    )
}

export default Header

