import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

function InputComponent({ value, onChange, ...inputProps }) {
  const [state, setstate] = useState(value)

  useEffect(() => {
    setstate(Mask(value))
    onChange({ target: { value: Mask(value), ...inputProps } })
  }, [value])

  const onInput = (ev) => {
    var valMasked = Mask(ev.target.value)
    setstate(valMasked)
    if (onChange) {
      onChange({ target: { value: valMasked, ...inputProps } })
    }
  }

  const Mask = (val) => {
    if (val === undefined) return
    return val.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
  }

  return <Input onChange={onInput} maxLength="9" type="text" value={state} {...inputProps} />
}

export default InputComponent
