import styled from 'styled-components'
import colors from '../../../Theme/colors'

export const CardWrapper = styled.div`
  position: relative;
  width: 22%;
  min-width: 150px;
  min-width: 150px;
  height: 300px;
  background-color: ${colors.WHITE};
  border-radius: 8px;
  margin-top: 15px;

  transition: transform 1.5s, color 2s ease, background-color 4s ease;
  transform-style: preserve-3d;

  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  &:hover {
    transform: rotateY(180deg);
    background-color: ${colors.COLOR_DEFAULT_BUTTON};
    color: ${colors.WHITE};

    .icon {
      color: ${colors.WHITE};
    }

    h4 {
      color: ${colors.WHITE};
    }
    img {
      display: none;
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 992px) {
    width: Calc(50% - 40px);
    margin: 10px 10px;
  }

  @media (max-width: 688px) {
    width: 100%;
    margin: 10px 20px;
  }
`
export const FrontCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  display: flex;
  flex-wrap: wrap;

  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: transform 1.5s ease;

  &:hover {
    transform: rotateY(180deg);
  }
`
export const BackCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;

  display: flex;
  flex-wrap: wrap;

  transform: rotateY(180deg);
  transition: transform 1.5s ease;
  backface-visibility: hidden;

  &:hover {
    transform: rotateY(180deg);
  }
`
export const CardHeader = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  padding: 8px;
`
export const Img = styled.img`
  width: 100%;
  height: 100%;
  //background-color: grey;
  transition: 2s ease;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`
export const Desc = styled.p`
  color: ${colors.WHITE};
  display: none;
  width: 80%;
  height: 100%;
  margin: 10px auto;

  //  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 2s ease;
`
export const CardBody = styled.div`
  padding: 0 5px 0 8px;
  width: 100%;
`
export const Title = styled.h4`
  color: black;
  font-weight: bold;
  margin: 0;
  transition: 2s ease;
`
export const CardFooter = styled.div`
  padding: 0 12px;
  width: 90%;
  height: 32.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 8px;
  //margin-left: -8px;
  gap: 8px;
  width: 100%;

  p {
    font-size: 14px;
    margin: 0;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`
export const InfoIcon = styled.div`
  color: ${colors.COLOR_DEFAULT_BUTTON};
  transition: 2s ease;
`
