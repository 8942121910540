import styled from 'styled-components'
import colors from '../../Theme/colors'
import { styling, sizing } from '../../Theme/fonts'

export const Wrapper = styled.div`
  display: flex;
`

export const Container = styled.div`
  display: flex;
  width: calc(100% - 15px);
  padding: 30px 5px 5px 10px;
  flex-direction: column;

  @media (max-width: 688px) {
    width: 100%;
    padding: 30px 0 0;
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 688px) {
    flex-direction: column;
  }
`

export const Close = styled.div`
  display: flex;
  width: 5px;
  height: 5px;
  padding: 0 5px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  :hover {
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 688px) {
    padding: 0;
  }
`
export const Info = styled.div`
  width: 50%;

  @media (max-width: 688px) {
    width: 100%;
  }
`

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`

export const CardContato = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Logo = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  img {
    width: 30%;
    object-fit: cover;
    transition: transform 1s ease;
    :hover {
      transform: scale(1.1);
    }
  }
`
export const Share = styled.div`
  width: 20%;
  display: flex;
  justify-content: space-between;
  margin: 0 20px 0 auto;

  div {
    width: 100%;
    display: flex;
    justify-content: space-around;
    > * {
      cursor: pointer;
      margin: 3px;
    }
  }

  div:nth-child(2) {
    display: flex;
    align-items: flex-end;
  }
  .btn a {
    color: ${colors.COLOR_DEFAULT_BUTTON};
    margin-top: 0px;
  }
`
export const Title = styled.h2`
  margin-top: 8px;
  font-family: ${styling.NORMAL};
  font-size: 22px;
  font-weight: bold;
`
export const Contato = styled.div``
export const ContatoItem = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.COLOR_DEFAULT_BUTTON};
  gap: 5px;
  font-family: ${styling.NORMAL};

  p {
    margin: 5px;
    color: #000;
    font-size: ${sizing.MEDIUM}px;
  }
  a {
    text-decoration: none;
    color: ${colors.BLACK};
  }

  :hover {
    a,
    p {
      color: ${colors.COLOR_DEFAULT_BUTTON};
    }
  }
`
export const Descricao = styled.p`
  width: 90%;
  font-family: ${styling.NORMAL};
  font-size: ${sizing.SMALL}px;
`
export const Mapa = styled.div`
  width: 60%;
  height: 150px;
  background-color: grey;

  @media (max-width: 688px) {
    width: 100%;
  }
`

export const Categorias = styled.div`
  width: calc(100% - 50px);
  padding: 10px 25px 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 688px) {
    display: none;
  }
`
export const CardCategoria = styled.div`
  width: calc(25% - 2px);
  margin: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  label {
    width: 100%;
    font-family: ${styling.NORMAL};
    font-size: ${sizing.SMALL}px;
    color: ${colors.COLOR_DEFAULT_BUTTON};
  }

  input + label {
    position: relative;
    cursor: pointer;
    padding-left: 40px;
    padding-right: 15px;
    padding-top: 20px;
  }

  input + label::before {
    //caixinha
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 0;
    bottom: 0;
    border: 3px solid ${colors.COLOR_DEFAULT_BUTTON};
    border-radius: 50%;
    vertical-align: bottom;
  }

  input:checked + label::after {
    content: '';
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 10px;
    height: 20px;
    border-right: 3px solid ${colors.GREEN};
    border-bottom: 3px solid ${colors.GREEN};
    transform: rotate(45deg);
  }
`

export const FrameFacebook = styled.iframe`
  width: 40%;
  height: 500px;
  overflow-y: auto;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.15);
    border-radius: 15px;
  }

  @media (max-width: 688px) {
    width: 100%;
    margin-top: 25px;
  }
`
export const Imagens = styled.div`
  width: 60%;

  @media (max-width: 688px) {
    width: 100%;
  }
`
