import React from 'react'
import BannerCarrossel from '../../Components/BannerCarrossel'
import { Wrapper, Container, Infos, Title, Texto, Video, Img, FrameYoutube } from './style'
import imagem from '../../Assets/Images/relatoriodeobras.png'

const ComoTrabalhamos = () => {
  return (
    <Wrapper>
      <BannerCarrossel />
      <Container>
        <Infos>
          <Title>Relatório de Obras para Sua Região</Title>
          <Texto>
            Nosso propósito é aumentar as suas vendas, através de um banco de dados de obras com mais e 200 obras que
            estão em andamento semanalmente.
          </Texto>
          <Title>Como fazemos isso?</Title>
          <Texto>
            Temos uma equipe de profissionais especializados em captação de obras, trabalhando para a sua empresa e
            coletando informações para sua equipe de vendas. Toda semana você vai ter em sua área de acesso novas
            possibilidades de negócios, além de sua propaganda chegar em forma de encarte pra todos os proprietários de
            obras. Nós te guiamos para o caminho certo até seu cliente sonho.
          </Texto>
          <Texto>
            <span>Seja um de nossos vários associados e aumente suas possibilidades de negócios.</span>
          </Texto>
        </Infos>
        <Video>
          <FrameYoutube
            src="https://www.youtube.com/embed/aka_Feevcro"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></FrameYoutube>
        </Video>
      </Container>
      <Img>
        <img src={imagem} alt="Como Trabalhamos" />
      </Img>
    </Wrapper>
  )
}

export default ComoTrabalhamos
