import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100px;
  margin: 0 auto;

  @media (max-width: 688px) {
    display: none;
  }
`
